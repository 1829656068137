import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

// COMPONENT IMPORTS
import SignIn from '../auth/SignIn';
import SignUp from '../auth/SignUp';
import MFASetUp from '../auth/MFASetUp';
import ThankYou from '../auth/ThankYou';
import GoogleSetupQR from '../auth/GoogleSetupQR';
import ForgotPassword from '../auth/ForgotPassword';
import ChangePassword from '../auth/ChangePassword';
import PageNotFound from '../components/PageNotFound';
import VerificationPin from '../auth/VerificationPin';
import BusinessForm from '../auth/SignUp/BusinessForm';
import EmailVerification from '../auth/EmailVerification';
import Confirmed from '../auth/SignUp/BusinessForm/Confirmed';
import ThankYouAfterSignUp from '../auth/ThankYouAfterSignUp';
import { authUserLogout, userSignOut } from '../slices/authSlice';
import { localObjectClear } from '../utils/system';

const ProtectedRoutes = () => {
  const dispatch = useDispatch();

  const token = window.localStorage.getItem('token');
  const { mfa } = useSelector((state) => state.auth);
  const { pathname } = useLocation();

  const handleUserLogout = async () => {
    const response = await dispatch(userSignOut()).unwrap();
    if (response) {
      dispatch(authUserLogout());
      localObjectClear();
    }
  };

  useEffect(() => {
    if (pathname.includes('/forgot-password/')) {
      token && handleUserLogout();
    }
  }, []);

  useEffect(() => {
    let reactClass = '';
    if (pathname === '/') {
      reactClass = 'signin';
    } else {
      reactClass = pathname.substring(1).replaceAll('/', '-');
    }
    document.body.classList.add(reactClass);
    return () => {
      document.body.classList.remove(reactClass);
    };
  }, [pathname]);
  if (mfa && mfa.verified && mfa.verified && token && !pathname.includes('/forgot-password/')) {
    return <Navigate to="/dashboard" replace />;
  } else {
    return <Outlet />;
  }
};

/* ============================== AUTH ROUTES ============================== */
const AuthRoutes = {
  path: '/',
  element: <ProtectedRoutes />,
  children: [
    {
      path: '/',
      element: <SignIn />
    },
    {
      path: 'sign-in',
      element: <Navigate to="/" replace />
    },
    {
      path: 'sign-up',
      element: <SignUp />
    },
    {
      path: 'sign-up/:token',
      element: <SignUp />
    },
    {
      path: 'mfa-setup',
      element: <MFASetUp />
    },
    {
      path: 'verification-pin',
      element: <VerificationPin />
    },
    {
      path: 'verify-business/:token',
      element: <BusinessForm />
    },
    {
      path: 'confirmed-business',
      element: <Confirmed />
    },
    {
      path: 'email-verification',
      element: <EmailVerification />
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />
    },
    {
      path: 'forgot-password/:token',
      element: <ChangePassword />
    },
    {
      path: 'thank-you',
      element: <ThankYou />
    },
    {
      path: 'thanks',
      element: <ThankYouAfterSignUp />
    },
    {
      path: 'authenticator-setup',
      element: <GoogleSetupQR />
    },
    {
      path: 'error-page',
      element: <PageNotFound />
    }
  ]
};

export default AuthRoutes;
