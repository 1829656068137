import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import CapsyncIcon from '../../CapsyncIcon';

const Navigation = ({ zoom, onZoom }) => {
  return (
    <div className="profile-navigation">
      <button
        disabled={zoom === 0}
        onClick={(e) => {
          onZoom(zoom - 0.1, true, "min");
        }}
        className={`cs-icon cs-neutral-10 ${zoom === 0 && "cs-disabled"}`}>
        <CapsyncIcon title="minusc-filled" size="14" />
      </button>
      <Form.Range value={zoom * 100} onChange={(e) => onZoom(e.target.value / 100)} />
      <button
        span
        onClick={() => {
          const demoZoom = zoom + 0.1 > 1 ? 1 : zoom + 0.1
          onZoom(demoZoom, true, "plus")
        }}
        disabled={zoom === 1}
        className={`cs-icon cs-neutral-10 ${zoom === 1 && "cs-disabled"}`}>
        <CapsyncIcon title="add-filled" size="14" />
      </button>
    </div>
  );
};

export default Navigation;

Navigation.propTypes = {
  zoom: PropTypes.any,
  onZoom: PropTypes.any
};
