import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Accordion, Button, Col, Container, Form, Row, Image as IMG } from 'react-bootstrap';
import { GithubPicker, SketchPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';

// CSS IMPORTS
import './branding.css';

// COMPONENT IMPORTS
import {
  domainNameField,
  welcomeMessageField,
  welcomeMessageTextAreaField
} from '../../validations';
import ClientLogin from './ClientLogin';
import LogoImageSetup from './LogoImageSetup';
import PreviewBranding from './PreviewBranding';
import PreviewTemplate from './PreviewTemplate';
import Modal from '../../../src/components/Modal';
import CapsyncIcon from '../../components/CapsyncIcon';
import CommonSpinner from '../../components/CommonSpinner';
import CapsyncLogo from '../../assets/images/brand/Logo.png';
import { contrast, localObjectClear } from '../../utils/system';
import CapsyncSmallLogo from '../../assets/images/brand/smallLogo.png';
import { TOAST_MESSAGE, VALIDATION_MESSAGE } from '../../constants/message';
import successVerification from '../../assets/images/icons/success-verification.svg';

// API
import {
  addingClientData,
  getBrandDetails,
  updateClientData,
  uploadWelcomeImage,
  validateDomainName
} from '../../slices/brandingSlice';
import { authUserLogout, getUserDetails, useUserID } from '../../slices/authSlice';

/* ============================== BRANDING ============================== */
const Branding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userId = useUserID();
  const { firm } = useSelector((state) => state.auth.user);
  const { brandRecord } = useSelector((state) => state.branding);
  const firmBrandDetails = firm.firm_brand_details;

  const inputRef = useRef(null);
  const colorRef = useRef(null);

  const selectedTemplateFromDatabase =
    (firm && firm.firm_brand_details && firm.firm_brand_details.welcome_template_type) || '';
  const selectedDomainFromDatabase =
    (firm && firm.firm_brand_details && firm.firm_brand_details.domain.split('.')[0]) || '';
  const selectedMsgFromDatabase =
    (firm && firm.firm_brand_details && firm.firm_brand_details.welcome_message) || '';
  const selectedDescriptionFromDatabase =
    (firm && firm.firm_brand_details && firm.firm_brand_details.welcome_message_description) || '';
  const selectedWelcomeMsgFromDatabase =
    (firm && firm.firm_brand_details && firm.firm_brand_details.welcome_message) || '';
  const selectedWelcomeMsgDescFromDatabase =
    (firm && firm.firm_brand_details && firm.firm_brand_details.welcome_message_description) || '';
  const selectedWelcomeImgNameFromDatabase =
    (firm && firm.firm_brand_details && firm.firm_brand_details.welcome_image) || '';
  const selectedPrimaryLogoFromDatabase =
    (firm && firm.firm_brand_details && firm.firm_brand_details.primary_logo_s3_url) || '';
  const selectedSecondaryLogoFromDatabase =
    (firm && firm.firm_brand_details && firm.firm_brand_details.small_logo_s3_url) || '';
  const selectedPrimaryColorFromDatabase =
    (firm && firm.firm_brand_details && firm.firm_brand_details.primary_color) || '';
  const isWelcomeMessageEnabledFromDatabase =
    firm && firm.firm_brand_details && firm.firm_brand_details.is_welcome_message_enabled;
  const [color, setColor] = useState({
    hex: `${firm && firm.firm_brand_details !== null ? firm.firm_brand_details.primary_color : '#0024d4'}`,
    rgb: { r: 0, g: 36, b: 212, a: 1 }
  });

  const [colorWarning, setColorWarning] = useState(false);
  const [activeKey, setActiveKey] = useState('0');
  const [primaryColor, setPrimaryColor] = useState(
    `${firm && firm.firm_brand_details !== null ? firm.firm_brand_details.primary_color : '#0024d4'}`
  );
  const [isChecked, setIsChecked] = useState(true);
  const [commonLoaderContinue, setCommonLoaderContinue] = useState(false);
  const [selectedImg, setSelectedImg] = useState({ id: '', link: '', name: '' });
  const [fileInput, setFileInput] = useState('');
  const [error, setError] = useState('');
  const [imgArray, setImgArray] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [domainNameValue, setDomainNameValue] = useState(selectedDomainFromDatabase);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(selectedTemplateFromDatabase || '1');
  const [isLoading, setIsLoading] = useState(true);
  const [resetClientLogin, setResetClientLogin] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [domainLoading, setDomainLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [primaryLogoFile, setPrimaryLogoFile] = useState(null);
  const [smallLogoFile, setSmallLogoFile] = useState(null);
  const [isCancel, setIsCancel] = useState(false);
  const [deletedImg, setDeletedImg] = useState([]);
  const [updateSelectedImg, setUpdateSelectedImg] = useState({ link: '', name: '' });
  const [logoDetails, setLogoDetails] = useState({
    primary_logo_url: CapsyncLogo,
    primary_logo_name: 'Logo.png',
    small_logo_url: CapsyncSmallLogo,
    small_logo_name: 'smallLogo.png',
    is_use_load_image: true
  });
  const [loaderCheckbox, setLoaderCheckbox] = useState(true);
  const [isLoaderClicked, setIsLoaderClicked] = useState(false);
  const [disableSmall, setDisableSmall] = useState(true);
  const [disablePrimary, setDisablePrimary] = useState(true);

  const variables = {
    '--primary-main': color.hex,
    '--primary-surface': 'color-mix(in srgb, var(--primary-main) 6%, var(--neutral-10) 20%)',
    '--primary-border': 'color-mix(in srgb, var(--primary-main) 30%, var(--neutral-10))',
    '--primary-hover': 'color-mix(in srgb, var(--primary-main) 80%, var(--neutral-100))',
    '--primary-pressed': 'color-mix(in srgb, var(--primary-main) 60%, var(--neutral-100))',
    '--primary-focus': 'color-mix(in srgb, var(--primary-main) 20%, var(--neutral-10))',
    '--primary-light': 'color-mix(in srgb, var(--primary-main) 30%, var(--neutral-10) 70%)'
  };

  const handleChangeColor = (color) => {
    setColor(color);
    const properties = { ...variables, '--primary-main': color.hex };
    Object.entries(properties).forEach((v) => colorRef.current.style.setProperty(v[0], v[1]));
  };

  useEffect(() => {
    const properties = { ...variables, '--primary-main': color.hex };
    Object.entries(properties).forEach((v) => colorRef.current.style.setProperty(v[0], v[1]));
  }, []);

  const resetColor = () => {
    setColor({ hex: '#0024d4', rgb: { r: 0, g: 36, b: 212, a: 1 } });
    const properties = { ...variables, '--primary-main': '#0024d4' };
    Object.entries(properties).forEach((v) => colorRef.current.style.removeProperty(v[0]));
  };

  useEffect(() => {
    const checkColorContrast = contrast(
      [color.rgb.r, color.rgb.g, color.rgb.b],
      [255, 255, 255]
    ).toFixed();
    setColorWarning(checkColorContrast < 4 ? true : false);
  }, [color]);

  const handleClickItem = (eventKey) => {
    if (activeKey !== eventKey) {
      setActiveKey(eventKey);
    } else {
      setActiveKey('0');
    }
  };

  const initialValuesDomainName = {
    domain_name: selectedDomainFromDatabase
  };

  const domainNameValidationSchema = yup.object({
    domain_name: domainNameField()
  });

  const domainName = useFormik({
    initialValues: initialValuesDomainName,
    validationSchema: domainNameValidationSchema,
    onSubmit: async (values, { setErrors }) => {
      setDomainLoading(true)
      try {
        await dispatch(validateDomainName(values.domain_name))
          .unwrap()
          .then((response) => {
            if (response.code === 200) {
              setActiveKey('1');
              setDomainNameValue(values.domain_name);
            } else {
              setErrors({ domain_name: response?.message });
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      } catch (error) {
        console.log('error', error)
      } finally {
        setDomainLoading(false)
      }
    }
  });

  const brandDetails = async () => {
    try {
      await dispatch(getBrandDetails(firm.id.toString())).unwrap();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('error', error);
    }
  };

  useEffect(() => {
    brandDetails();
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2
        }
      }
    ]
  };

  const handlePrimaryColor = () => {
    setPrimaryColor(color.hex);
    setActiveKey('3');
  };

  const toggleMessage = () => {
    setIsChecked(!isChecked);
  };

  const initialValuesClientLoginPage = {
    welcome_msg: selectedMsgFromDatabase,
    welcome_msg_description: selectedDescriptionFromDatabase
  };

  const clientLoginPageValidationSchema = yup.object({
    welcome_msg: welcomeMessageField(isChecked),
    welcome_msg_description: welcomeMessageTextAreaField(isChecked)
  });

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      values.is_welcome_msg_enabled = isChecked;
      if (!isChecked) {
        values.welcome_msg = '';
        values.welcome_msg_description = '';
      }
      setIsLoaderClicked(false);
      setResetClientLogin(false);
      setCommonLoaderContinue(false);
      setDisableSmall(true);
      setDisablePrimary(true);

      const clientData = {
        domain: domainNameValue.toLowerCase(),
        is_use_load_image: logoDetails.is_use_load_image,
        primary_color: primaryColor,
        welcome_message: values.welcome_msg,
        welcome_message_description: values.welcome_msg_description,
        is_welcome_message_enabled: isChecked,
        welcome_template_type: selectedTemplate,
        primary_logo_url: primaryLogoFile,
        small_logo_url: smallLogoFile,
        ...((selectedImg.link.startsWith('data:image/png;base64') ||
          selectedImg.link.startsWith('data:image/jpeg;base64') ||
          selectedImg.id) && {
          welcome_image_url: selectedImg.id
        })
      };
      const formData = new FormData();
      for (const key in clientData) {
        if (Object.prototype.hasOwnProperty.call(clientData, key)) {
          formData.append(key, clientData[key]);
        }
      }
      formData.append('primary_logo_name', logoDetails?.primary_logo_name);
      formData.append('small_logo_name', logoDetails?.small_logo_name);
      if (clientData.welcome_image_url) {
        formData.append('welcome_image_url', clientData.welcome_image_url);
      }
      if (
        selectedImg.link.startsWith('data:image/png;base64') ||
        selectedImg.link.startsWith('data:image/jpeg;base64') ||
        selectedImg.id
      ) {
        formData.append('welcome_image_file', selectedImg?.id);
      }

      if (firm && firm.firm_brand_details === null) {
        const response = await dispatch(addingClientData(formData)).unwrap();
        if (response && response.code === 200) {
          await dispatch(getUserDetails(`${userId}`)).unwrap();
          setShowSuccessModal(true);
        }
        setIsLoading(false);
      } else {
        const response = await dispatch(updateClientData(formData)).unwrap();
        if (response && response.code === 200) {
          await dispatch(getUserDetails(`${userId}`)).unwrap();
          toast.success(TOAST_MESSAGE.CUSTOM_BRANDING_UPDATED_SUCCESS);
          brandDetails();
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log('error', error);
    }
  };

  const clientLoginPage = useFormik({
    initialValues: initialValuesClientLoginPage,
    validationSchema: clientLoginPageValidationSchema,
    onSubmit
  });

  useEffect(() => {
    if (firm && firm.firm_brand_details !== null) {
      clientLoginPage.values.welcome_msg = firm.firm_brand_details.welcome_message;
      clientLoginPage.values.welcome_msg_description =
        firm.firm_brand_details.welcome_message_description;
    }
  }, []);

  const mouseDownCoords = (e) => {
    window.checkForDrag = e.clientX;
  };

  const clickOrDrag = (e, selectedImg) => {
    const mouseUp = e.clientX;
    if (mouseUp < window.checkForDrag + 6 && mouseUp > window.checkForDrag - 6) {
      setSelectedImg({ id: selectedImg.id, link: selectedImg.link, name: selectedImg.name });
      setError('');
    }
  };

  const clickOrDragTemplate = (e, selectedTemp) => {
    const mouseUp = e.clientX;
    if (mouseUp < window.checkForDrag + 6 && mouseUp > window.checkForDrag - 6) {
      setSelectedTemplate(selectedTemp.index);
      if (firmBrandDetails === null) {
        setIsChecked(selectedTemp.index === '2' ? false : true);
      } else {
        setIsChecked(
          selectedTemp.index === '2' ? false : firmBrandDetails?.is_welcome_message_enabled
        );
      }
    }
  };

  const validateFile = (file) => {
    const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes
    const allowedFormats = ['image/png', 'image/jpeg'];
    if (!allowedFormats.includes(file.type)) {
      setError(VALIDATION_MESSAGE.FILE_ONLY_IMAGE_ALLOWED);
      return false;
    } else if (file.size && file.size > maxFileSize) {
      setError(VALIDATION_MESSAGE.FILE_SIZE_EXCEED_ONE_MB);
      return false;
    } else if (imgArray.length > 6) {
      setError(VALIDATION_MESSAGE.MAX_FILE_NUMBER_EXCEED);
      return false;
    }

    return true;
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileNameExists = imgArray.some((img) => img.name === file.name);

      if (fileNameExists) {
        setError(`File with the name ${file.name} already exists.`);
        return;
      } else {
        const isValidFile = validateFile(file);
        if (isValidFile) {
          setFileInput(file);
          setError(null);
        }
      }
    }
  };

  const handleFileUploadButtonClick = async () => {
    setIsUploading(true);
    try {
      if (fileInput) {
        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.onload = async () => {
            const width = img.width;
            const height = img.height;
            if (width > 1920 || height > 1080) {
              setError('Image dimensions exceed 1920x1080');
              setIsUploading(false);
            } else {
              const uploadedImg = {
                link: reader.result,
                name: fileInput.name
              };
              const payload = {
                welcome_image_url: fileInput,
                welcome_image_name: fileInput.name,
                user_id: userId
              };
              const formData = new FormData();
              for (const key in payload) {
                if (Object.prototype.hasOwnProperty.call(payload, key)) {
                  formData.append(key, payload[key]);
                }
              }
              await dispatch(uploadWelcomeImage(formData)).then((res) => {
                if (res?.payload?.code === 200) {
                  brandDetails();
                  setUpdateSelectedImg({ link: uploadedImg.link, name: uploadedImg.name });
                  toast.success(res.payload.message);
                  setIsUploading(false);
                } else {
                  toast.error(res.payload.message);
                }
              });
            }
          };
          img.src = reader.result;
        };
        reader.readAsDataURL(fileInput);
        setFileInput('');
        inputRef.current.value = '';
      }
    } catch (error) {
      console.log('error', error)
    }
  };

  useEffect(() => {
    if (brandRecord && brandRecord.welcome_images_history) {
      setImgArray([...brandRecord.welcome_images_history].reverse());

      setIsUploading(false);
      setIsDeleting(false);
      if (deletedImg.length > 0 && isLoading) {
        const updateImgArray = imgArray.filter((imgObj) => imgObj.name !== deletedImg.name);
        setImgArray(updateImgArray);
      }
    }
    return () => {
      setIsLoading(false);
      setDeletedImg([]);
      setError('');
    };
  }, [brandRecord?.welcome_images_history]);

  useEffect(() => {
    if (firm && firm.firm_brand_details) {
      const welcomeImg = brandRecord?.welcome_images_history?.filter((img) => {
        return img.name === firm.firm_brand_details.welcome_image;
      });
      if (welcomeImg && welcomeImg.length > 0) {
        setSelectedImg(...welcomeImg);
      }
      setLogoDetails({
        primary_logo_url: firm.firm_brand_details.primary_logo_s3_url,
        primary_logo_name: firm.firm_brand_details.primary_logo_url,
        small_logo_url: firm.firm_brand_details.small_logo_s3_url,
        small_logo_name: firm.firm_brand_details.small_logo_url,
        is_use_load_image: firm.firm_brand_details.is_use_load_image
      });
      setIsChecked(firm.firm_brand_details.is_welcome_message_enabled);
    }
    if (updateSelectedImg) {
      const welcomeImg = brandRecord?.welcome_images_history?.filter((img) => {
        return img.name === updateSelectedImg.name;
      });
      if (welcomeImg && welcomeImg.length > 0) {
        setSelectedImg(...welcomeImg);
      }
    }
  }, [firm, brandRecord, updateSelectedImg]);

  useEffect(() => {
    if (firm && !firm.firm_brand_details) {
      setSelectedImg(imgArray[0]);
    }
  }, [deletedImg]);

  const handleSuccessModal = () => {
    setShowSuccessModal(false);
    navigate('/dashboard');
  };

  const modalBodyElement = () => {
    return (
      <div className="cs-validation-popup">
        <IMG width="84" height="84" src={successVerification} />
        <h3 className="cs-regular-body-text-m cs-neutral-100">
          Congratulations! You've finished configuring your company branding. Please allow up to 24
          hours to see these updates.
        </h3>
      </div>
    );
  };

  const requestFullScreen = useCallback(() => {
    const element = colorRef.current;
    if (element) {
      const requestMethod =
        element.requestFullscreen ||
        element.mozRequestFullScreen ||
        element.webkitRequestFullscreen ||
        element.msRequestFullscreen;
      if (requestMethod) {
        requestMethod.call(element);
      }
    }
  }, []);

  const exitFullScreen = useCallback(() => {
    const exitMethod =
      document.exitFullscreen ||
      document.mozCancelFullScreen ||
      document.webkitExitFullscreen ||
      document.msExitFullscreen;
    if (exitMethod) {
      exitMethod.call(document);
    }
  }, []);

  const handleFullScreenChange = useCallback(() => {
    setIsFullScreen(
      !!(
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      )
    );
  }, []);

  useEffect(() => {
    const events = [
      'fullscreenchange',
      'webkitfullscreenchange',
      'mozfullscreenchange',
      'MSFullscreenChange'
    ];
    events.forEach((event) => document.addEventListener(event, handleFullScreenChange));

    return () => {
      events.forEach((event) => document.removeEventListener(event, handleFullScreenChange));
    };
  }, [handleFullScreenChange]);

  const handleLogoutUser = () => {
    dispatch(authUserLogout());
    navigate('/');
    localObjectClear();
  };

  return (
    <div className="branding-section">
      <Container fluid>
        {firm && firm.firm_brand_details === null && (
          <div className="branding-title">
            <Row>
              <Col lg={12} className="text-center">
                <h1 className="cs-semi-bold-h3">Let’s setup your client experience</h1>
              </Col>
            </Row>
          </div>
        )}
        <div className="branding-container">
          <Row>
            <Col lg={5}>
              <div className="branding-left">
                <div className="cs-accordion cs-branding-accordion">
                  <Accordion defaultActiveKey="0" activeKey={activeKey}>
                    <Accordion.Item
                      eventKey="0"
                      className={
                        activeKey == 0
                          ? 'custom-domain-section cs-accordion-active'
                          : 'custom-domain-section'
                      }>
                      <Accordion.Header onClick={() => handleClickItem('0')}>
                        <span className="cs-regular-h3 cs-neutral-100">Custom domain</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        {firm?.firm_brand_details ? (
                          <h3 className="cs-regular-body-text-l cs-primary">
                            {firm?.firm_brand_details?.domain}
                          </h3>
                        ) : (
                          <React.Fragment>
                            <p className="cs-light-body-text-l cs-neutral-80">
                              Enter your custom domain name in the field below. Once saved, this
                              cannot be changed.
                            </p>
                            <Form onSubmit={domainName.handleSubmit}>
                              <Row>
                                <Col>
                                  <Form.Group
                                    className="cs-form-group"
                                    data-title={process.env.REACT_APP_COMMAN_URL}>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter your domain"
                                      name="domain_name"
                                      value={domainName.values.domain_name}
                                      onChange={domainName.handleChange}
                                      disabled={!!firm?.firm_brand_details}
                                    />
                                    {domainName.errors.domain_name &&
                                      domainName.touched.domain_name ? (
                                      <span className="form-error-msg cs-light-body-text-s cs-danger">
                                        {domainName.errors.domain_name}
                                      </span>
                                    ) : null}
                                  </Form.Group>
                                  <Button
                                    className="cs-btn-primary sm-btn cs-regular-h5"
                                    type="submit"
                                    disabled={
                                      domainName.values.domain_name === '' ||
                                      !!firm?.firm_brand_details || domainLoading
                                    }>
                                    Continue
                                    {domainLoading && (
                                      <CommonSpinner
                                        classParent="cs-modal-common-spinner cs-neutral-60"
                                        size={14}
                                      />
                                    )}
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          </React.Fragment>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item
                      eventKey="1"
                      className={
                        activeKey == 1
                          ? 'logo-image-section cs-accordion-active'
                          : 'logo-image-section'
                      }>
                      <Accordion.Header onClick={() => handleClickItem('1')}>
                        <span className="cs-regular-h3 cs-neutral-100">Logo Image</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="cs-light-body-text-l cs-neutral-80">
                          Upload your company logo in the fields below.
                        </p>
                        <LogoImageSetup
                          setLogoDetails={setLogoDetails}
                          setSmallLogoFile={setSmallLogoFile}
                          setPrimaryLogoFile={setPrimaryLogoFile}
                          setActiveKey={setActiveKey}
                          commonLoaderContinue={commonLoaderContinue}
                          setCommonLoaderContinue={setCommonLoaderContinue}
                          setIsLoaderClicked={setIsLoaderClicked}
                          loaderCheckbox={loaderCheckbox}
                          setLoaderCheckbox={setLoaderCheckbox}
                          disableSmall={disableSmall}
                          setDisableSmall={setDisableSmall}
                          disablePrimary={disablePrimary}
                          setDisablePrimary={setDisablePrimary}
                        />
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item
                      eventKey="2"
                      className={
                        activeKey == 2
                          ? 'primary-color-section cs-accordion-active'
                          : 'primary-color-section'
                      }>
                      <Accordion.Header onClick={() => handleClickItem('2')}>
                        <span className="cs-regular-h3 cs-neutral-100">Primary color</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="cs-light-body-text-l cs-neutral-80">
                          Select your company's brand color. This will be the primary color used
                          throughout the application.
                        </p>
                        <Form>
                          <Row>
                            <Col>
                              <div className="color-container">
                                <div className="color-row">
                                  <Button
                                    type="button"
                                    className="cs-reset-btn cs-regular-h5 cs-btn-icon-tertiary"
                                    onClick={resetColor}>
                                    Reset
                                  </Button>
                                  <SketchPicker color={color.hex} onChange={handleChangeColor} />
                                </div>
                                {colorWarning && (
                                  <p className="cs-regular-body-text-s cs-danger">
                                    <b>Warning!</b> The color you have chosen has a low contrast
                                    ratio. It may be difficult for your clients to see. Please look
                                    over the previews thoroughly before saving this page.
                                  </p>
                                )}
                                <GithubPicker
                                  color={color.hex}
                                  onChange={handleChangeColor}
                                  width="100%"
                                  colors={[
                                    '#212529',
                                    '#343A40',
                                    '#495057',
                                    '#6C757D',
                                    '#6E1423',
                                    '#85182A',
                                    '#A11D33',
                                    '#A10D33',
                                    '#FF7B00',
                                    '#FF8800',
                                    '#FF9500',
                                    '#FFA200',
                                    '#03045E',
                                    '#023E8A',
                                    '#0077B6',
                                    '#0096C7',
                                    '#38160D',
                                    '#411D13',
                                    '#4A2419',
                                    '#532C1E',
                                    '#FF5400',
                                    '#FF6000',
                                    '#FF6D00',
                                    '#FF7900',
                                    '#10002B',
                                    '#240046',
                                    '#3C096C',
                                    '#5A189A',
                                    '#081C15',
                                    '#1B4332',
                                    '#2D6A4F',
                                    '#40916C'
                                  ]}
                                />
                              </div>
                              <Button
                                className="cs-btn-primary sm-btn cs-regular-h5"
                                disabled={color.hex == primaryColor}
                                onClick={handlePrimaryColor}>
                                Continue
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Accordion.Body>
                    </Accordion.Item>
                    <ClientLogin
                      clientLoginPage={clientLoginPage}
                      selectedImg={selectedImg}
                      settings={settings}
                      toggleMessage={toggleMessage}
                      mouseDownCoords={mouseDownCoords}
                      clickOrDrag={clickOrDrag}
                      error={error}
                      handleFileInputChange={handleFileInputChange}
                      handleFileUploadButtonClick={handleFileUploadButtonClick}
                      activeKey={activeKey}
                      handleClickItem={handleClickItem}
                      inputRef={inputRef}
                      imgArray={imgArray}
                      setResetClientLogin={setResetClientLogin}
                      isChecked={isChecked}
                      setSelectedTemplate={setSelectedTemplate}
                      domainNameValue={domainNameValue}
                      logoDetails={logoDetails}
                      clickOrDragTemplate={clickOrDragTemplate}
                      selectedTemplate={selectedTemplate}
                      fileInput={fileInput}
                      setSelectedImg={setSelectedImg}
                      domainName={domainName}
                      primaryColor={primaryColor}
                      setDeletedImg={setDeletedImg}
                      deletedImg={deletedImg}
                      isUploading={isUploading}
                      setIsDeleting={setIsDeleting}
                      isDeleting={isDeleting}
                      brandDetails={brandDetails}
                    />
                  </Accordion>
                </div>
                <div className="cs-btn-group">
                  {firm && firm.firm_brand_details === null ? (
                    <Button
                      variant="primary"
                      disabled={
                        domainNameValue === '' ||
                        selectedImg?.link === '' ||
                        logoDetails?.primary_logo_url === CapsyncLogo ||
                        logoDetails?.small_logo_url === CapsyncSmallLogo ||
                        selectedTemplate === '' ||
                        isLoading
                      }
                      // disabled={domainName.values.domain_name === ""}
                      className="cs-btn-primary sm-btn cs-regular-h5"
                      type="submit"
                      form="client-login">
                      Save
                      {isLoading && <CommonSpinner classParent="cs-modal-common-spinner" size={14} />}
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      disabled={
                        (domainNameValue === selectedDomainFromDatabase &&
                          selectedImg?.name === selectedWelcomeImgNameFromDatabase &&
                          logoDetails?.primary_logo_url === selectedPrimaryLogoFromDatabase &&
                          logoDetails?.small_logo_url === selectedSecondaryLogoFromDatabase &&
                          selectedTemplate === selectedTemplateFromDatabase &&
                          clientLoginPage?.values.welcome_msg_description ===
                          selectedWelcomeMsgDescFromDatabase &&
                          clientLoginPage?.values.welcome_msg === selectedWelcomeMsgFromDatabase &&
                          primaryColor === selectedPrimaryColorFromDatabase &&
                          isWelcomeMessageEnabledFromDatabase === isChecked &&
                          !resetClientLogin &&
                          !isLoaderClicked) ||
                        isLoading
                      }
                      className="cs-btn-primary sm-btn cs-regular-h5"
                      type="submit"
                      form="client-login">
                      Save
                      {isLoading && <CommonSpinner classParent="cs-modal-common-spinner" size={14} />}
                    </Button>
                  )}
                  <Button
                    variant="primary"
                    className="cs-btn-secondary sm-btn cs-regular-h5 cs-disable"
                    disabled={firmBrandDetails != null}
                    onClick={() => setIsCancel(true)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={7}>
              <div
                className={`branding-right ${isFullScreen ? 'fullscreen-preview' : ''} ${isFullScreen && activeKey !== '3' ? 'dashboard-full-preview' : ''}`}
                ref={colorRef}>
                <h3 className="cs-regular-h3 cs-neutral-100">Preview</h3>
                {firm && firm.firm_brand_details && (
                  <span className="cs-regular-body-text-l" onClick={requestFullScreen}>
                    Full Preview
                  </span>
                )}
                {isFullScreen && (
                  <Button className="cs-regular-h5 exit-fullscreen-button" onClick={exitFullScreen}>
                    <span>
                      <CapsyncIcon title="close-outlined" size="16" />
                    </span>
                    <span>Exit Preview</span>
                  </Button>
                )}
                {activeKey === '3' ? (
                  <PreviewTemplate
                    logo={logoDetails?.primary_logo_url}
                    selectedImg={selectedImg}
                    selectedTemplate={selectedTemplate}
                    clientLoginPage={clientLoginPage}
                    welcomeMsg={clientLoginPage?.values.welcome_msg}
                    welcomeMsgDescription={clientLoginPage?.values.welcome_msg_description}
                    isChecked={isChecked}
                  />
                ) : (
                  <PreviewBranding logo={logoDetails.primary_logo_url} />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      {showSuccessModal && (
        <Modal
          parentClassName="cs-modal-blur"
          show={true}
          isCloseButton={false}
          className="cs-md-modal"
          title="Setup successful"
          body={modalBodyElement()}
          saveButtonLabel="Continue"
          modalFooterClass="cs-center-btn"
          handleOnSave={handleSuccessModal}
        />
      )}
      {isCancel && !firm?.firm_brand_details && (
        <Modal
          show={isCancel}
          title="Are you sure?"
          className="cs-modalbox cs-delete-modal cs-modal"
          isCloseButton={false}
          body={
            <div className="cs-text-center logout-modal">
              <span className="icon cs-icon">
                <CapsyncIcon title="error-validation" size="100" />
              </span>
              <div className="cs-regular-body-text-m cs-neutral-90">
                Are you sure you want to logout from Capsync?
              </div>
            </div>
          }
          cancelButtonLabel="Log out now"
          saveButtonLabel="Stay on Capsync"
          handleOnSave={() => setIsCancel(false)}
          handleOnCancel={handleLogoutUser}
          deleteBodyText="Are you sure you want to logout from Capsync?"
          deleteButtonText="Stay on Capsync"
          fromBranding={true}
          modalFooterClass="cs-center-btn"
        />
      )}
    </div>
  );
};

export default Branding;
