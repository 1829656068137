/* eslint-disable */
import React, { useState } from 'react';
import { Button, Col, Modal as BootstrapModal, Row, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CreateCompanyModal from './CreateCompanyModal';
import CapsyncIcon from '../../../components/CapsyncIcon';
import CustomTable from '../../../components/CustomTable';
import { TOAST_MESSAGE } from '../../../constants/message';

// API
import { UpdateCompany, addCompany } from '../../../slices/clientSlice';

/* ============================== EDIT COMPANY MODAL ============================== */
const EditCompanyModal = ({
  handleCloseModel,
  setClientAdded,
  companyDetails,
  selectedUsersDetails,
  setSelectedUsersDetails,
  isSelectedUsers,
  professionalUserId,
  setSelectedUsers,
  handleOpenModel
}) => {
  const dispatch = useDispatch();

  const [addPartner, setAddPartner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editCompany, setEditCompany] = useState('');
  const [validationMessage, setvalidationMessage] = useState('');
  const COLUMNS = [
    {
      label: 'Name',
      renderCell: (item) => {
        return isSelectedUsers ? item.first_name + ' ' + item.last_name : item.display_name;
      }
    },
    {
      label: 'Email',
      renderCell: (item) => item.email
    }
  ];
  const handleAddMorePartnerClick = () => {
    setAddPartner(true);
  };

  const updateCompanyName = () => {
    setLoading(true);
    const data = {
      id: companyDetails.id,
      company_name: editCompany
    };
    dispatch(UpdateCompany(data))
      .unwrap()
      .then((response) => {
        if (response.code == 200) {
          handleCloseModel();
          toast.success(response.message);
          setClientAdded && setClientAdded(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log('error', error.message);
      });
  };

  const addSelectedUsersToCompany = () => {
    setLoading(true);
    const selectedIndividualId = selectedUsersDetails.map((client) => client.id);
    const reqData = {
      clients: [],
      professional_user_id: professionalUserId,
      company_name: editCompany,
      is_existing_individual: true,
      individual_ids: selectedIndividualId
    };

    dispatch(addCompany(reqData))
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          handleCloseModel();
          toast.success(TOAST_MESSAGE.COMPANY_ADDED_SUCCESS);
          setSelectedUsersDetails([]);
          setSelectedUsers([]);
          setClientAdded && setClientAdded(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setvalidationMessage(error);
      });
  };
  const handleCancel = () => {
    handleCloseModel();
    if (isSelectedUsers) {
      setSelectedUsersDetails([]);
      setSelectedUsers([]);
    }
  };
  return (
    <React.Fragment>
      {!addPartner && (
        <BootstrapModal
          show={true}
          className="cs-modalbox company-details-modal"
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="cs-md-modal">
          <BootstrapModal.Header>
            <BootstrapModal.Title className="cs-semi-bold-h3 cs-neutral-100">
              Company Details
            </BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <React.Fragment>
              <Row>
                <Col lg={12} md={12}>
                  <Form.Group className="cs-form-group" controlId="validationFormik03">
                    <Form.Label>Edit company name</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={70}
                      onChange={(e) => {
                        setEditCompany(e.target.value);
                        setvalidationMessage('');
                      }}
                      defaultValue={isSelectedUsers ? '' : companyDetails.company_name}
                      className={
                        (companyDetails && companyDetails.company_name) || editCompany
                          ? 'cs-input-field-active'
                          : ''
                      }
                      autoComplete="off"
                      placeholder="ABC LLC"
                    />
                    {validationMessage ? (
                      <span className="cs-light-body-text-s cs-danger">{validationMessage}</span>
                    ) : null}
                  </Form.Group>

                  <CustomTable
                    columns={COLUMNS}
                    data={
                      companyDetails
                        ? companyDetails.company_clients
                        : isSelectedUsers
                          ? selectedUsersDetails
                          : []
                    }
                    isPaginate={false}
                    totalRecords={companyDetails || 0}
                  />

                  <div className="view-and-manage-container add-more-btn">
                    <Button
                      type="submit"
                      disabled={
                        editCompany === '' && !(companyDetails && companyDetails.company_name)
                      }
                      className="cs-btn-icon-tertiary cs-regular-h5"
                      onClick={handleAddMorePartnerClick}>
                      <span className="cs-primary">
                        <CapsyncIcon title="add-filled" size="14" />
                      </span>
                      Add more partners
                    </Button>
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <div className="cs-modal-btn">
              <Button className="cs-btn-secondary md-btn cs-regular-h5" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                className="cs-btn-primary md-btn cs-regular-h5"
                type="submit"
                onClick={isSelectedUsers ? addSelectedUsersToCompany : updateCompanyName}
                disabled={!editCompany || loading}>
                Save
              </Button>
            </div>
          </BootstrapModal.Footer>
        </BootstrapModal>
      )}
      {addPartner && (
        <CreateCompanyModal
          handleCloseModel={() => handleCloseModel('addCompany')}
          setClientAdded={setClientAdded}
          companyDetails={companyDetails}
          isEditModal={true}
          handleOpenModel={handleOpenModel}
          setAddPartner={setAddPartner}
          isSelectedUsers={isSelectedUsers}
          professionalUserId={professionalUserId}
          editCompany={editCompany ? editCompany : companyDetails.company_name}
          setSelectedUsersDetails={setSelectedUsersDetails}
          setSelectedUsers={setSelectedUsers}
          selectedUsersDetails={selectedUsersDetails}
          editCompanyDetails={companyDetails?.company_clients}
        />
      )}
    </React.Fragment>
  );
};

// PROPS TYPE
EditCompanyModal.propTypes = {
  handleCloseModel: PropTypes.func,
  setClientAdded: PropTypes.func,
  companyDetails: PropTypes.array,
  setIsModelOpen: PropTypes.func,
  selectedUsersDetails: PropTypes.array,
  setSelectedUsersDetails: PropTypes.func,
  setSelectedUsers: PropTypes.func,
  isSelectedUsers: PropTypes.bool,
  professionalUserId: PropTypes.string
};

export default EditCompanyModal;
