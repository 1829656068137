import React from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import CryptoJS from 'crypto-js';

// COMPONENT IMPORTS
import { usAreaCodes } from '../config/countryAreaCodes';
import { VALIDATION_MESSAGE } from '../constants/message';

/* ============================== COMMON FUNCTIONS ============================== */

const combine = (e, check) => {
  const obj = {};
  const storeValue = e;
  obj.status = storeValue ? true : false;
  obj.value = check;
  return obj;
};

const fetchMinutes = (params) => {
  const seconds = Math.floor((new Date().getTime() - new Date(params).getTime()) / 1000);
  let interval = seconds / 31536000;
  const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
  if (interval > 1) {
    return rtf.format(-Math.floor(interval), 'year');
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return rtf.format(-Math.floor(interval), 'month');
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return rtf.format(-Math.floor(interval), 'day');
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return rtf.format(-Math.floor(interval), 'hour');
  }
  interval = seconds / 60;
  if (interval > 1) {
    return rtf.format(-Math.floor(interval), 'minute');
  }
  return rtf.format(-Math.floor(interval), 'second');
};

const datediffDay = (date) => {
  const currentDate = new Date().toJSON().slice(0, 10);
  if (currentDate === date) {
    return 'today';
  } else {
    const dt1 = new Date(currentDate);
    const dt2 = new Date(date);
    const diffDT = Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    );

    if (diffDT === 1) {
      return diffDT + ' day left';
    } else {
      return diffDT + ' days left';
    }
  }
};

const calculateAmortization = (Principal, rate, time, num, payFrequency) => {
  const interestRate = rate / 100; // Convert the interest rate from percentage to decimal
  const ratePerPeriod = interestRate / num;
  const numberOfPayments = time;
  const numerator = 1 - Math.pow(1 + ratePerPeriod, -numberOfPayments);
  const monthlyPaymentt = (Principal * ratePerPeriod) / numerator;
  return monthlyPaymentt;
};

const calculatePaymentPeriod = (P, r, t, n) => {
  const principal = P == '' ? 0 : P;
  const interestRate = r / 100;
  const ratePerPeriod = interestRate / n;

  const numerator = 1 - Math.pow(1 + ratePerPeriod, -(n * t));

  const TotalBalance =
    typeof P == 'number' ? principal : P.includes(',') ? principal.replace(/,/g, '') : principal;

  const monthlyPaymentt = (TotalBalance * ratePerPeriod) / numerator;

  const simpleInterest = monthlyPaymentt * (t * n);

  if (P == 0 || r == 0 || t == 0 || n == 0) {
    return 0;
  } else {
    return simpleInterest.toFixed(2);
  }
};

const calculateAmortizationLiabilityAutomobile = (Principal, rate, time, num, payFrequency) => {
  const interestRate = rate / 100;
  const ratePerPeriod = interestRate / num;
  const numerator = payFrequency === 'Yearly' ? -(num * time) : -time;
  const monthlyPaymentt = (Principal * ratePerPeriod) / 1 - Math.pow(1 + ratePerPeriod, numerator);
  return monthlyPaymentt;
};

const attomdataAddressFind = async (address) => {
  const options = {
    method: 'GET',
    url: `https://api.gateway.attomdata.com/propertyapi/v1.0.0/property/address?address=${address}`,

    headers: {
      'content-type': ' application/json',
      apikey: process.env.REACT_APP_ATTOMDATA_REALESTATE_KEY
      // "X-RapidAPI-Host": "realty-mole-property-api.p.rapidapi.com	",
    }
  };
  return options;
};

const attomdataPriceFetch = async (propertyDetails) => {
  const optionsPrice = {
    method: 'GET',
    url: `https://api.gateway.attomdata.com/propertyapi/v1.0.0/allevents/detail?id=${propertyDetails}`,

    headers: {
      'content-type': ' application/json',
      // apikey: "b008e81acdddcf95c184cb25e08078e1",
      apikey: process.env.REACT_APP_ATTOMDATA_REALESTATE_KEY
      // "X-RapidAPI-Host": "realty-mole-property-api.p.rapidapi.com	",
    }
  };
  return optionsPrice;
};

const generateString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const addHeaderInAxiosAPI = () => {
  const token = window.localStorage.getItem('user')
    ? JSON.parse(window.localStorage.getItem('user')).token
    : '';
  const url = axios.create({
    // baseURL: backAppURl + "/api",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return url;
};

const headerAddToken = () => {
  const token = window.localStorage.getItem('user')
    ? JSON.parse(window.localStorage.getItem('user')).token
    : '';
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

const mongoDBAxiosAPI = () => {
  const url = axios.create({});
  return url;
};

const renderHTML = (rawHTML) =>
  React.createElement('div', {
    dangerouslySetInnerHTML: { __html: rawHTML }
  });

const loginStatusStore = (roleId, userId) => {
  sessionStorage.setItem(
    'loginStatus',
    JSON.stringify({
      loginStatus: true,
      user_role: roleId
    })
  );
  sessionStorage.setItem('id', userId);
  localStorage.setItem(
    'loginStatus',
    JSON.stringify({
      loginStatus: true,
      user_role: roleId
    })
  );
  localStorage.setItem('id', userId);
  return true;
};

const deviceVerifyCookieStore = (id, deviceVerify) => {
  const cookies = new Cookies();
  if (deviceVerify) {
    cookies.set(`deviceAuth24Hr-${id}`, `${deviceVerify}`, {
      path: '/',
      expires: new Date(Date.now() + 86400000 * 30)
    });
  }
  return true;
};

const currencyFormat = (num) => {
  return Number(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const getValidAddress = (placesData) => {
  const address = { line1: '', line2: '', city: '', state: '', country: '', postal_code: '' };
  const isValid = {
    street_number: false,
    city: false
  };
  placesData.forEach((component) => {
    if (component.types.includes('street_number')) {
      address['line1'] = component.short_name;
      isValid.street_number = true;
    }
    if (component.types.includes('route')) {
      address['line1'] += ' ' + component.short_name;
    }
    if (component.types.includes('subpremise')) {
      address['line2'] = component.short_name;
    }
    if (component.types.includes('locality')) {
      address['city'] = component.short_name;
      isValid.city = true;
    } else if (component.types.includes('sublocality') && !address['city']) {
      address['city'] = component.short_name;
      isValid.city = true;
    }
    if (component.types.includes('administrative_area_level_1')) {
      address['state'] = component.long_name;
    }
    if (component.types.includes('administrative_area_level_1')) {
      address['state_code'] = component.short_name;
    }
    if (component.types.includes('country')) {
      address['country'] = component.short_name;
    }
    if (component.types.includes('postal_code')) {
      address['postal_code'] = component.short_name;
    }
  });
  if (!isValid.city) {
    return {
      error: 'Address must have city.',
      address
    };
  } else if (!isValid.street_number) {
    return {
      error: 'Address must have street number.',
      address
    };
  }
  return address;
};

const getAddressObject = (placesData) => {
  const address = { line1: '', line2: '', city: '', state: '', country: '', postal_code: '' };

  placesData.filter((component) => {
    if (component.types.includes('street_number')) {
      if (address['line1']) {
        address['line1'] = component.short_name + ' ' + address['line1'];
      } else {
        address['line1'] = component.short_name;
      }
    }
    if (component.types.includes('route')) {
      if (address['line1']) {
        address['line1'] += ' ' + component.short_name;
      } else {
        address['line1'] = component.short_name;
      }
    }
    if (component.types.includes('subpremise')) {
      address['line2'] = component.short_name;
    }
    if (component.types.includes('locality') || component.types.includes('sublocality_level_1')) {
      address['city'] = component.short_name;
    }
    if (component.types.includes('administrative_area_level_1')) {
      address['state'] = component.long_name;
    }
    if (component.types.includes('administrative_area_level_1')) {
      address['state_code'] = component.short_name;
    }
    if (component.types.includes('country')) {
      address['country'] = component.short_name;
    }
    if (component.types.includes('postal_code')) {
      address['postal_code'] = component.short_name;
    }
  });
  return address;
};

const setAddressString = (address) => {
  let newAddress = '';
  if (address == null) {
    return '';
  }
  if (address.line1 && address.line1.length !== 0) {
    newAddress += address.line1 + ', ';
  }
  if (address.line2 && address.line2.length !== 0) {
    newAddress += address.line2 + ', ';
  }
  return newAddress + address.city + ', ' + address.state + ' ' + address.country;
};

const trialExpiryDays = (date) => {
  const expDate = new Date(date);
  const curr = new Date();
  const difference = (expDate - curr) / (1000 * 3600 * 24);
  if (difference < 0) {
    return 10;
  } else {
    return parseInt(difference);
  }
};

const loanTermType = ['Yearly', 'Monthly'];
const NotesPlaceholder = 'Optional';
const validCountryCodes = ['+1', '+44', '+91'];

const checkPhoneValid = (
  event,
  setPhoneNo,
  setMsg,
  setLoading,
  errMessage = 'Enter a valid phone number'
) => {
  if (event) {
    if (isValidPhoneNumber(event) === true) {
      setPhoneNo(event);
      setMsg('');
      setLoading(false);
    } else {
      if (!validCountryCodes.includes(event)) {
        setPhoneNo(event);
        setMsg(errMessage);
        setLoading(true);
      } else {
        setPhoneNo('');
        setMsg('');
        setLoading(false);
      }
    }
  } else {
    setPhoneNo('');
    setMsg('');
    setLoading(false);
  }
};

const phoneHandleChange = (
  val,
  setPhoneNo,
  setMsg,
  setLoading,
  errMessage = 'Enter a valid phone number'
) => {
  if (val) {
    if (isValidPhoneNumber(val) === true) {
      setPhoneNo(val);
      setMsg('');
      setLoading(false);
    } else {
      // if (!usAreaCodes.includes(val)) {
      //   setPhoneNo(val);
      //   setMsg(errMessage);
      //   setLoading(true);
      // } else {
      //   setPhoneNo('');
      //   setMsg("");
      //   setLoading(false);
      // }
    }
  } else {
    setPhoneNo('');
    setMsg('');
    setLoading(false);
  }
};

const selectOptionsArr = (arr) => {
  const options = [];
  arr.map((data) => {
    options.push({ value: data, label: data });
  });
  return options;
};

const localStorageClear = () => {
  const webPass = process.env.REACT_APP_WEB_PASSWORD;
  window.localStorage.clear();
  window.localStorage.setItem('tempPass', webPass);
};

const shortLengthLabel = (string, length) => {
  return string > 30 ? string.slice(0, length) + '...' : string;
};

const formatPhoneNumber = (string) => {
  if (string) {
    const formatted = formatPhoneNumberIntl(string);
    if (formatted.startsWith('+1')) {
      return '+1 ' + formatted.substring(3).split(' ').join('-').replace(/-/, '-');
    }
    return formatted.split(' ').join('-');
  }
  return string;
};

const checkDomainAccept = (email) => {
  const domainAccept = [
    'com',
    'org',
    'io',
    'net',
    'edu',
    'gov',
    'ae',
    'int',
    'dot',
    'co',
    'in',
    'ca',
    'fr',
    'br',
    'de',
    'ru',
    'it',
    'es',
    'nl',
    'jp',
    'ar',
    'uk',
    'mx',
    'id',
    'au',
    'biz',
    'info',
    'us',
    'ch',
    'se',
    'no',
    'dk',
    'fi',
    'be',
    'at',
    'cz',
    'pl',
    'pt',
    'gr',
    'hu',
    'ro',
    'tr',
    'sg',
    'hk',
    'tw',
    'vn',
    'th',
    'ph',
    'my',
    'kr',
    'cn',
    'is'
  ];
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)$/;

  if (!email) {
    return true;
  }
  if (!emailRegex.test(email)) {
    return false;
  }
  const emailDomains = email && email.split('.')[email.split('.').length - 1];
  return email ? domainAccept.includes(emailDomains) : true;
};
const formatNumberWithThousandSeparator = (value, locale = 'en-US') => {
  if (typeof value === 'string') {
    value = parseFloat(value); // Convert string to a number
  }
  if (isNaN(value)) {
    return value;
  }
  return value.toLocaleString(locale);
};
const handleValidatePhoneNo = (value = '', isSubmit = false) => {
  if (value) {
    if (
      isValidPhoneNumber(value) === true &&
      usAreaCodes.includes(Number(value.split('+1')[1].substr(0, 3))) &&
      value.split('+1')[1].length == 10
    ) {
      return { phoneValue: value, phoneError: '', triggeredSubmit: isSubmit ? true : false };
    } else {
      return {
        phoneValue: value,
        phoneError: !value
          ? VALIDATION_MESSAGE.PHONE_REQUIRED
          : VALIDATION_MESSAGE.INVALID_PHONE_NUMBER,
        triggeredSubmit: isSubmit ? true : false
      };
    }
  } else if (!value || !isValidPhoneNumber(value)) {
    return {
      phoneValue: value,
      phoneError: !value
        ? VALIDATION_MESSAGE.PHONE_REQUIRED
        : VALIDATION_MESSAGE.INVALID_PHONE_NUMBER,
      triggeredSubmit: isSubmit ? true : false
    };
  } else {
    return { phoneValue: '', phoneError: '', triggeredSubmit: isSubmit ? true : false };
  }
};

const roles = {
  individual: { name: 'Individual', roleID: '1' },
  standard: { name: 'Standard', roleID: '2' },
  professional: { name: 'Tax Professional', roleID: '3' },
  admin_financial_advisor: { name: 'Admin Financial Advisor', roleID: '4' },
  master_admin_financial_advisor: { name: 'Master Admin Financial Advisor', roleID: '5' }
};

const cryptoJsEncryption = async (id) => {
  return new Promise((resolve) => {
    const dataId = encodeURIComponent(
      CryptoJS.AES.encrypt(JSON.stringify(id), process.env.REACT_APP_CRYPTO_KEY).toString()
    );
    return resolve(dataId);
  });
};

export {
  combine,
  cryptoJsEncryption,
  calculateAmortization,
  calculatePaymentPeriod,
  attomdataAddressFind,
  attomdataPriceFetch,
  generateString,
  addHeaderInAxiosAPI,
  headerAddToken,
  fetchMinutes,
  datediffDay,
  mongoDBAxiosAPI,
  roles,
  renderHTML,
  loginStatusStore,
  deviceVerifyCookieStore,
  currencyFormat,
  calculateAmortizationLiabilityAutomobile,
  // DateFormat,
  getAddressObject,
  getValidAddress,
  setAddressString,
  trialExpiryDays,
  loanTermType,
  NotesPlaceholder,
  // addTimePeriod,
  validCountryCodes,
  checkPhoneValid,
  selectOptionsArr,
  localStorageClear,
  shortLengthLabel,
  formatPhoneNumber,
  phoneHandleChange,
  checkDomainAccept,
  formatNumberWithThousandSeparator,
  handleValidatePhoneNo
  // emailSchema
};
