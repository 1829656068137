import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Link, useOutletContext } from 'react-router-dom';

// COMPONENT IMPORTS
import CustomTable from '../../../components/CustomTable';
import SkeletonSettingsTable from '../../components/skeleton/SkeletonSettingsTable';

// API
import { useUser } from '../../../slices/authSlice';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import CapsyncIcon from '../../../components/CapsyncIcon';
import AccreditationLogo from '../../../assets/images/icons/AccreditationLogo.svg';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCheckOutForm from '../components/BillingHistory/StripeCheckOutForm';
import CommonPurchaseModal from '../components/BillingHistory/CommonPurchaseModal';
import UpgradeStorageModal from '../components/BillingHistory/UpgradeStorageModal';
import {
  getFirmPaymentDetails,
  firmTransactionHistory,
  useTransactionHistory,
  useFirmPaymentDetails
} from '../../../slices/billingHistorySlice';
import UpgradeModal from '../components/BillingHistory/UpgradeModal';
import { socket } from '../../../config/Socket';
import DefaultPaymentMethod from '../components/BillingHistory/DefaultPaymentMethod';
import BillingCardDetailModal from '../components/BillingHistory/BillingCardDetailModal';
import CurrentMonthlyBill from '../components/BillingHistory/CurrentMonthlyBill';
import BillingHistorySkeleton from '../components/skeleton/BillingHistorySkeleton';
import BillingDateComponent from '../components/BillingHistory/BillingDateComponent';
import BillingDateSkeleton from '../components/skeleton/BillingDateSkeleton';
import PaymentMethodSkeleton from '../components/skeleton/PaymentMethodSkeleton';
import AddOnsCardSkeleton from '../components/skeleton/AddOnsCardSkeleton';
import { formatNumberWithThousandSeparator } from '../../../utils/common';

/* ============================== BILLING HISTORY ============================== */
const BillingHistory = () => {
  const dispatch = useDispatch();
  const user = useUser();
  const billingData = useTransactionHistory();

  const firmPaymentDetails = useFirmPaymentDetails();
  const [commonPurchaseType, setCommonPurchaseType] = useState('');
  const [cardModalType, setCardModalType] = useState('');
  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
  const [billingDetailModal, setBillingDetailModal] = useState(false);
  const [upgradeStorageOpen, setUpgradeStorageOpen] = useState(false);
  const [showStripeForm, setShowStripeForm] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const getFirmPaymentFun = async () => {
    dispatch(getFirmPaymentDetails({ firm_id: user?.firm_id })).unwrap();
  };
  useEffect(() => {
    getFirmPaymentFun();
    onPaginationValueChange({
      page: 1,
      limit: 10,
      isPaginate: false
    });

    const handleAccreditationPayment = (data) => {
      if (data?.message === 'Payment Success') {
        onPaginationValueChange({
          page: 1,
          limit: 10,
          isPaginate: false
        });
      }
    };
    socket.on('Accreditation Payment', handleAccreditationPayment);
    return () => {
      socket.off('Accreditation Payment', handleAccreditationPayment);
    };
  }, []);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#0024d4',
      colorBackground: '#f5f5f5',
      borderColor: '#c2c2c2',
      colorText: '#0a0a0a',
      colorDanger: '#e00000',
      gridRowSpacing: '24px',
      gridColumnSpacing: '20px',
      tabSpacing: '8px',
      colorTextPlaceholder: '#757575',
      tabIconColor: '#757575',
      tabIconHoverColor: '#0024d4',
      iconCardCvcColor: '#0024d4',
      borderRadius: '4px'
    },
    rules: {
      '.Tab': {
        border: '1px solid #c2c2c2',
        boxShadow: 'none'
      },

      '.Tab:hover': {
        color: 'var(--colorIconTabSelected)',
        borderColor: 'var(--colorIconTabSelected)',
        backgroundColor: '#eef1ff'
      },

      '.Tab:focus': {
        boxShadow: 'none'
      },

      '.Tab--selected': {
        borderColor: '#0024d4',
        backgroundColor: '#eef1ff',
        color: 'var(--colorIconTabSelected)',
        tabIconSelectedColor: '#0a0a0a',
        boxShadow: 'none'
      },
      '.Tab--selected:focus': {
        boxShadow: 'none'
      }
    }
  };

  const options = {
    // clientSecret: clientSecret,
    mode: cardModalType === 'AddOrChangeMethod' ? 'setup' : 'payment',
    amount: 5000,
    currency: 'usd',
    payment_method_types: ['card', 'us_bank_account'],
    payment_method_options: {
      us_bank_account: { verification_method: 'instant' }
    },
    appearance
  };

  const onPaginationValueChange = (option) => {
    const paginationLimits = {
      firm_id: user?.firm_id,
      page: option.page,
      limit: option.limit,
      isPaginate: option.limit !== 'all' ? true : false
    };
    getFirmTransaction(paginationLimits);
  };

  const getFirmTransaction = async (params) => {
    await dispatch(firmTransactionHistory(params)).unwrap();
  };

  const COLUMNS = [
    {
      label: 'Date',
      renderCell: (item) => <span>{moment(item.transaction_date).format('L')}</span>,
      width: 254
    },
    {
      label: 'Details',
      renderCell: (item) => {
        return <span>{item?.payment_details}</span>;
      },
      width: 254
    },
    {
      label: 'Amount',
      renderCell: (item) => {
        const parsedItem = item?.transaction_response && JSON.parse(item?.transaction_response);
        return <span>$ {formatNumberWithThousandSeparator(parsedItem?.amount / 100)}</span>;
      },
      width: 254
    },
    {
      label: 'Invoice',
      renderCell: (item) => (
        <Link
          to={item?.invoice_url}
          target="_blank"
          className="cs-regular-body-text-l export-btn cs-primary cursor-pointer">
          Export to Pdf
        </Link>
      ),
      width: 254
    }
  ];
  const billingHistoryCard = [
    {
      type: 'users',
      name: 'Users',
      image: 'user-multiple-filled',
      description: 'Professional and client licenses',
      button: 'Purchase new licenses',
      click: () => {
        setCommonPurchaseType('users');
        setPurchaseModalOpen(true);
      }
    },
    {
      type: 'accreditation',
      name: 'Accreditation',
      image: 'user-multiple-filled',
      description: 'Preload credits for team members',
      button: 'Purchase new credits',
      click: () => {
        setCommonPurchaseType('accreditation');
        setPurchaseModalOpen(true);
      }
    },
    {
      type: 'storage',
      name: '$100/month',
      image: 'folder-icon-filled',
      description: `Additional storage Up to ${firmPaymentDetails?.file_storage_size}GB`,
      button: firmPaymentDetails?.is_file_management_plan_upgraded
        ? 'Downgrade file storage'
        : 'Upgrade file storage',
      click: () => {
        setUpgradeStorageOpen(true);
      }
    }
  ];

  const [loading] = useOutletContext();
  // const loading = true;

  return (
    <>
      <div className="billing-subscription-tab cs-subscription-section billing-subscription-page">
        <Container fluid>
          <div className="billing-row">
            <Row>
              <Col lg={4}>
                {loading ? (
                  <BillingHistorySkeleton />
                ) : (
                  <CurrentMonthlyBill setShowUpgradeModal={setShowUpgradeModal} />
                )}
              </Col>
              <Col lg={4}>{loading ? <BillingDateSkeleton /> : <BillingDateComponent />}</Col>
            </Row>
          </div>
          <div className="cs-payment-container">
            <Row>
              <Col lg={4}>
                {loading ? (
                  <PaymentMethodSkeleton />
                ) : (
                  <div className="payment-cards">
                    <div className="payment-method">
                      <span className="cs-regular-sub-heading-m  cs-neutral-100">
                        Payment Method
                      </span>
                      <div className="text-button cursor-pointer">
                        <Button
                          type="submit"
                          className="cs-regular-h5 cs-btn-icon-tertiary"
                          onClick={() => {
                            setBillingDetailModal(true);
                            setCardModalType('AddOrChangeMethod');
                          }}>
                          <span className="cs-primary">
                            <CapsyncIcon title="add-filled" size="14" />
                          </span>
                          Add/Change method
                        </Button>
                      </div>
                    </div>
                    <DefaultPaymentMethod active={true} />
                  </div>
                )}
              </Col>
            </Row>
          </div>

          <div className="add-ons-row">
            <Row>
              <Col lg={12}>
                <h3 className="cs-medium-sub-heading-m cs-neutral-100">Add ons</h3>
              </Col>
              {billingHistoryCard?.map((items) => {
                return (
                  <Col lg={4} key={items?.type}>
                    {loading ? (
                      <AddOnsCardSkeleton />
                    ) : (
                      <div
                        className={`billing-box add-ons-box ${items?.type === 'accreditation' ? 'accreditation-bill' : items?.type === 'users' ? 'users-licenses' : ''} `}>
                        <div className="add-ons-icon ">
                          {items?.type === 'accreditation' ? (
                            <Image
                              width="40"
                              height="40"
                              src={AccreditationLogo}
                              alt="Accreditation Logo"
                            />
                          ) : (
                            <span className="cs-primary">
                              <CapsyncIcon size="22" title={items?.image} />
                            </span>
                          )}
                        </div>
                        {items?.type === 'storage' ? (
                          <div className="card-plan-amount">
                            <h2 className="cs-regular-h3 cs-neutral-100">
                              $
                              {!firmPaymentDetails?.is_file_management_plan_upgraded
                                ? 0
                                : firmPaymentDetails?.file_storage_upgrade_price}
                            </h2>
                          </div>
                        ) : (
                          <h4 className="cs-regular-h4 cs-neutral-100">{items?.name}</h4>
                        )}

                        <h5 className="cs-regular-h5 cs-neutral-60">{items?.description}</h5>
                        <Button
                          className="cs-btn-primary lg-btn cs-regular-h5"
                          onClick={items?.click}>
                          {items?.button}
                        </Button>
                        <span
                          className="cs-regular-h5 cursor-pointer cs-primary"
                          onClick={() => {
                            items?.name === 'Accreditation'
                              ? setCommonPurchaseType('clearHistory')
                              : setCommonPurchaseType('checkLicenses');
                            setPurchaseModalOpen(true);
                          }}>
                          {items?.name === 'Accreditation'
                            ? 'Credit history'
                            : items?.name === 'Users'
                              ? 'Check licenses'
                              : null}
                        </span>
                      </div>
                    )}
                  </Col>
                );
              })}
            </Row>
          </div>
          <div className="cs-setting-page cs-audit-logs-sec">
            {loading ? (
              <SkeletonSettingsTable tableData={[1, 2, 3]} />
            ) : (
              <CustomTable
                columns={COLUMNS}
                totalRecords={
                  billingData?.count?.itemCount
                    ? billingData?.count?.itemCount
                    : billingData?.transaction_data?.length
                }
                data={billingData?.transaction_data}
                isPaginate={true}
                onPaginationValueChange={onPaginationValueChange}
                columnVisible={false}
              />
            )}
          </div>
        </Container>
      </div>
      <CommonPurchaseModal
        type={commonPurchaseType}
        setBillingDetailModal={setBillingDetailModal}
        setCardModalType={setCardModalType}
        purchaseModalOpen={purchaseModalOpen}
        setPurchaseModalOpen={setPurchaseModalOpen}
        setShowStripeForm={setShowStripeForm}
      />
      <UpgradeModal showUpgradeModal={showUpgradeModal} setShowUpgradeModal={setShowUpgradeModal} />
      <UpgradeStorageModal
        setUpgradeStorageOpen={setUpgradeStorageOpen}
        upgradeStorageOpen={upgradeStorageOpen}
      />
      <BillingCardDetailModal
        billingDetailModal={billingDetailModal}
        setCardModalType={setCardModalType}
        setBillingDetailModal={setBillingDetailModal}
        setShowStripeForm={setShowStripeForm}
      />
      <Elements stripe={stripePromise} options={options}>
        <StripeCheckOutForm
          type={cardModalType}
          setShowStripeForm={setShowStripeForm}
          commonPurchaseType={commonPurchaseType}
          setPurchaseModalOpen={setPurchaseModalOpen}
          setBillingDetailModal={setBillingDetailModal}
          showStripeForm={showStripeForm}
        />
      </Elements>
    </>
  );
};

export default BillingHistory;
