import React, { useEffect, useState } from 'react';
import { Button, NavDropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import ChooseUserModal from './ChooseUserModal';
import CustomTable from '../../../components/CustomTable';
import CapsyncIcon from '../../../components/CapsyncIcon';
import CheckBox from '../../../components/FormFields/CheckBox';
import DeleteModal from '../../../components/DeleteModal';
import SearchBox from '../../../components/FormFields/SearchBox';

// API
import { getClientsByCollabUser, removeClientFromUser } from '../../../slices/collaborationSlice';

/* ============================== MANAGE CLIENT TEAM COLLABORATION MODAL ============================== */
const ManageClientTeamCollabModel = ({
  manageClientModelId,
  handleCloseModel,
  userId,
  handleAddMoreClient
}) => {
  const dispatch = useDispatch();

  const [clientsData, setClientsData] = useState([]);
  const [clientsSearchData, setClientsSearchData] = useState([]);
  const [assignedCounts, setAssignedCounts] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState({
    removeUser: false,
    removeUserMul: false,
    moveUser: false
  });

  useEffect(() => {
    getAllClientList();
  }, []);

  const getAllClientList = () => {
    dispatch(getClientsByCollabUser(userId))
      .unwrap()
      .then((res) => {
        if (res) {
          setClientsData(res);
        }
      });
  };

  const handleSelectedUser = (key, item) => {
    if (key === 'all') {
      if (clientsData?.length === selectedUsers?.length) {
        setSelectedUsers([]);
      } else {
        setSelectedUsers(clientsData.map((client) => client.id));
      }
    } else {
      setAssignedCounts(item);
      const index = selectedUsers.indexOf(key);
      if (index !== -1) {
        if (selectedUsers.length === 1) {
          setAssignedCounts(null);
        }
        setSelectedUsers([...selectedUsers.slice(0, index), ...selectedUsers.slice(index + 1)]);
      } else {
        setSelectedUsers([...selectedUsers, key]);
      }
    }
  };

  const COLUMNS = [
    {
      label: '',
      renderCell: (item) => {
        return (
          <CheckBox
            name="select-header"
            handleChange={() => {
              handleSelectedUser(item.id, item);
            }}
            className={item?.company_details.length > 0 ? 'cs-disabled' : ''}
            disabled={item?.company_details.length > 0}
            values={{ 'select-header': selectedUsers.indexOf(item.id) > -1 }}
          />
        );
      },
      width: '30'
    },
    {
      label: 'Name',
      renderCell: (item) => {
        if (item?.company_details.length > 0) {
          setShowWarning(true);
        }
        return (
          <span className={`${item?.company_details.length > 0 ? 'cs-neutral-50' : ''}`}>
            {item.display_name}
          </span>
        );
      },
      width: '210'
    },
    {
      label: 'Email',
      renderCell: (item) => {
        return (
          <span className={`${item?.company_details.length > 0 ? 'cs-neutral-50' : ''}`}>
            {item.email}
          </span>
        );
      },
      width: '288'
    },
    {
      label: 'Date Added',
      renderCell: (item) =>
        item?.is_initial_setup_done && item?.is_verified ? (
          <span className={`${item?.company_details.length > 0 ? 'cs-neutral-50' : ''}`}>
            {moment(item.createdAt).format('MM/DD/YYYY')}
          </span>
        ) : (
          <span className="cs-regular-body-text-s cs-badge cs-warning">Pending</span>
        )
    },
    {
      label: 'Phone',
      renderCell: (item) => {
        return (
          <span className={`${item?.company_details.length > 0 ? 'cs-neutral-50' : ''}`}>
            {item.phone_no || '-'}
          </span>
        );
      },
      width: '180'
    }
  ];

  const onHandleCloseModal = (key) => {
    if (key === 'moveUser') {
      getAllClientList();
    }

    setIsModelOpen({
      ...isModelOpen,
      [key]: false
    });
  };

  const onHandleOpenModal = (key) => {
    setIsModelOpen({
      ...isModelOpen,
      [key]: true
    });
  };

  const onRemoveUserClick = () => {
    dispatch(
      removeClientFromUser({
        professional_user_id: userId.toString(),
        remove_users: selectedUsers.map((id) => id.toString())
      })
    )
      .unwrap()
      .then(() => {
        getAllClientList();
        setAssignedCounts(null);
        setIsModelOpen({
          ...isModelOpen,
          removeUser: false,
          removeUserMul: false
        });
      });
    setSelectedUsers([]);
  };

  useEffect(() => {
    if (searchValue === '') {
      setClientsSearchData(clientsData);
    } else {
      const clientsDataSearch = clientsData.filter((item) => {
        return (
          item?.display_name?.toLowerCase().includes(searchValue) ||
          item?.email?.toLowerCase().includes(searchValue)
        );
      });
      setClientsSearchData(clientsDataSearch);
    }
  }, [searchValue, clientsData]);

  return (
    <React.Fragment>
      <Modal
        title={'View & Manage Users'}
        show={true}
        className="cs-lg-modal cs-new-user-modal cs-common-modal-length "
        handleClose={handleCloseModel}
        body={
          <div className="view-and-manage-container">
            <div className="view-and-manage-header">
              <span className="cs-regular-h5 cs-neutral-80">List of the assigned users</span>
              <div className="view-and-manage-top">
                <SearchBox
                  label={``}
                  value={searchValue}
                  placeholder={'Search'}
                  onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
                />
                {selectedUsers?.length ? (
                  <div className="cs-table-user-action">
                    <NavDropdown
                      title={
                        <span className="cursor-pointer cs-neutral-80">
                          <CapsyncIcon title="option-vertical-filled" size="18" />
                        </span>
                      }
                      className="cs-relative cs-dropdown-nav">
                      <NavDropdown.Item onClick={() => onHandleOpenModal('moveUser')}>
                        Move
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => {
                          // assignedCounts?.assigned_counts <= 1 ? onHandleOpenModal('removeUserMul') :
                          onHandleOpenModal('removeUser');
                        }}>
                        Delete
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                ) : null}
              </div>
            </div>
            <CustomTable
              className="cs-checkbox-table"
              columns={COLUMNS}
              type="ManageClientTeamCollabModel"
              data={clientsSearchData}
              isPaginate={false}
              totalRecords={0}
            />
            <Button onClick={handleAddMoreClient} className="cs-btn-icon-tertiary cs-regular-h5">
              <span className="add-filled cs-primary icon cs-icon">
                <CapsyncIcon title="add-filled" size="14" />
              </span>
              <span>Add more clients</span>
            </Button>

            {showWarning && (
              <div className="cs-modal-text cs-light-body-text-m cs-neutral-80">
                <strong className="cs-primary">Note:</strong> Disabled items cannot be removed
                because the individual user is associated with a company
              </div>
            )}
          </div>
        }
        isCloseButton={false}
        saveButtonLabel="Save"
        cancelButtonLabel="Close"
        handleOnSave={handleCloseModel}
        handleOnCancel={handleCloseModel}
      />
      {isModelOpen.moveUser && (
        <ChooseUserModal
          handleCloseModel={() => onHandleCloseModal('moveUser')}
          userId={userId}
          clientIds={selectedUsers}
          type="TeamCollaboration"
          manageClientModelId={manageClientModelId}
        />
      )}
      {isModelOpen.removeUserMul && (
        <ChooseUserModal
          handleCloseModel={() => onHandleCloseModal('removeUserMul')}
          onHandleConfirm={onRemoveUserClick}
          getAllClientList={getAllClientList}
          manageClientModelId={userId}
          userId={userId}
          clientIds={selectedUsers}
          deleteUser={true}
          type="TeamCollaboration"
        />
      )}
      {isModelOpen.removeUser && (
        <DeleteModal
          show={isModelOpen.removeUser}
          onHandleClose={() => onHandleCloseModal('removeUser')}
          onHandleConfirm={() => {
            assignedCounts?.assigned_counts <= 1
              ? onHandleOpenModal('removeUserMul')
              : onRemoveUserClick();
          }}
          deleteBodyText={
            assignedCounts?.assigned_counts <= 1
              ? 'You are about to delete this user, but before deleting this user, you need to re-assign clients. By hitting continue, you can move forward.'
              : 'You are going to delete this entry, Once it is deleted, you will be not able to recover this data'
          }
          deleteButtonText={assignedCounts?.assigned_counts <= 1 ? 'Continue' : 'Delete'}
        />
      )}
    </React.Fragment>
  );
};

// PROPS TYPE
ManageClientTeamCollabModel.propTypes = {
  handleCloseModel: PropTypes.func,
  userId: PropTypes.number,
  manageClientModelId: PropTypes.any,
  handleAddMoreClient: PropTypes.func
};

export default ManageClientTeamCollabModel;
