import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useFormik } from 'formik';

// COMPONENT IMPORTS
import EditProfie from '../components/EditProfie';
import CapsyncIcon from '../../../components/CapsyncIcon';
import { formatPhoneNumber } from '../../../utils/common';
import Avatar from '../../../components/userProfile/Avatar';
import InputBox from '../../../components/FormFields/InputBox';
import UpdateAvatar from '../../../components/userProfile/UpdateAvatar';
import SkeletonAccount from '../../components/skeleton/SkeletonAccount';
import PasswordConfirmationDialog from '../components/PasswordConfirmationDialog';

// API
import { useUser, userForgotPassword } from '../../../slices/authSlice';

/* ============================== ACCOUNT DETAILS ============================== */
const AccountDetails = () => {
  const dispatch = useDispatch();
  const user = useUser();

  const [editProfileOpen, setEditProfileOpen] = useState(false);
  const [openAvatarModal, setOpenAvatarModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const imageURL =
    user &&
    user?.user_personal_details &&
    user?.user_personal_details?.profile_image_url &&
    user?.user_personal_details?.profile_image_url.replace(
      /(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/,
      ''
    );
  const selectedAvatarFromDb =
    (user && user.user_personal_details && user.user_personal_details.profile_image_url) || null;
  const [selectedAvatar, setSelectedAvatar] = useState(imageURL);
  const addressString = user?.user_personal_details?.address;
  const address =
    typeof addressString === 'string' && addressString.trim()
      ? JSON.parse(addressString || '{}')
      : {};

  const [initialValues, setInitialValues] = useState({
    first_name: '',
    last_name: '',
    firm_name: '',
    email: '',
    crd_number: '',
    street_name: '',
    phone_no: '',
    city: '',
    state: '',
    zip_code: '',
    country: 'USA'
  });

  useEffect(() => {
    setInitialValues({
      first_name: user?.first_name,
      firm_name: user?.firm?.firm_name,
      last_name: user?.last_name,
      email: user?.email,
      phone_no: user?.phone_no,
      crd_number: user?.firm?.crd_number,
      street_name: `${address?.line1}, ${address?.state}, ${address?.city}, ${address?.country}, ${address?.postal_code}`,
      city: address?.city,
      state: address?.state,
      country: address?.country,
      zip_code: address?.postal_code
    });
  }, [user]);

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    enableReinitialize: true
  });

  const handleAvatarSelected = (title) => {
    setSelectedAvatar(title);
  };

  const handleOpenPasswordModal = async () => {
    await dispatch(userForgotPassword({ email: user.email })).unwrap();
    setShowPasswordModal(true);
  };

  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
  };

  const [loading] = useOutletContext();

  const trimedSrc =
    user &&
    user?.user_personal_details &&
    user?.user_personal_details?.profile_image_url &&
    user?.user_personal_details?.profile_image_url.replace(
      /(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/,
      ''
    );

  useEffect(() => {
    if (user && user?.user_personal_details && user?.user_personal_details?.profile_image_url) {
      const avatarSix = user?.user_personal_details?.profile_image_url.replace(
        /(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/,
        ''
      );
      if (avatarSix === '/users/avatar/avatar_6.png') {
        setSelectedAvatar(null);
      }
    }
  }, [user]);

  return (
    <React.Fragment>
      <div className="cs-setting-page">
        {loading ? (
          <SkeletonAccount />
        ) : (
          <div className="cs-account-details">
            <div className="cs-profile">
              <div className="cs-profile-update">
                <Avatar
                  className={`cs-avatar-md ${trimedSrc === '/users/avatar/avatar_6.png' || user?.user_personal_details?.profile_image_url === null ? 'cs-avatar-text' : 'cs-avatar-img'}`}
                  src={`${user.user_personal_details.profile_image_url}`}
                />
                <span
                  className="cursor-pointer edit-image-filled cs-neutral-10"
                  onClick={() => setOpenAvatarModal(true)}>
                  <CapsyncIcon title="edit-image-filled" size="14" />
                </span>
              </div>
              <div className="cs-profile-details">
                <h2 className="cs-semi-bold-h2 cs-neutral-100 cs-relative">{user?.display_name}</h2>
                <h4 className="cs-regular-h4 cs-neutral-80">{user?.email}</h4>
              </div>
            </div>
            <div className="cs-profile-form">
              <div className="cs-profile-action">
                <h3 className="cs-regular-h3 cs-neutral-100">Personal information</h3>
                <Button
                  variant="primary"
                  className="cs-btn-primary sm-btn cs-regular-h5"
                  onClick={() => {
                    setEditProfileOpen(true);
                  }}>
                  Edit
                </Button>
              </div>
              <Form onSubmit={handleSubmit} className="personal-inform-form">
                <Row>
                  <Col md={6}>
                    <InputBox
                      label="First name"
                      name="first_name"
                      placeholder="Enter first name"
                      values={values}
                      errors={errors}
                      touched={touched}
                      disabled={true}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col md={6}>
                    <InputBox
                      label="Last name"
                      name="last_name"
                      placeholder="Enter last name"
                      values={values}
                      errors={errors}
                      touched={touched}
                      disabled={true}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <InputBox
                      label="Email"
                      name="email"
                      placeholder="Email address"
                      values={values}
                      errors={errors}
                      touched={touched}
                      disabled={true}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col md={6}>
                    <InputBox
                      label="Phone number"
                      name="phone_no"
                      placeholder="Enter phone Number"
                      values={{
                        ...values,
                        phone_no: values.phone_no ? formatPhoneNumber(values.phone_no) : ''
                      }}
                      errors={errors}
                      touched={touched}
                      disabled={true}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <InputBox
                      label="Address"
                      name="street_name"
                      placeholder="Enter Address"
                      values={values}
                      errors={errors}
                      touched={touched}
                      disabled={true}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col md={6}>
                    <InputBox
                      label="Company name"
                      name="firm_name"
                      placeholder="Enter company name"
                      values={values}
                      errors={errors}
                      touched={touched}
                      disabled={true}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {user?.firm?.crd_number && (
                      <InputBox
                        label="CRD number"
                        name="crd_number"
                        disabled={true}
                        placeholder="Enter CRD number"
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                      />
                    )}
                  </Col>
                </Row>
                <div className="account-change-password">
                  <span
                    className="cs-regular-h5 cs-primary cursor-pointer cs-primary"
                    id="reset-password"
                    onClick={handleOpenPasswordModal}>
                    Change password
                  </span>
                  <Button
                    variant="primary"
                    className="cs-btn-primary sm-btn cs-regular-h5"
                    onClick={() => {
                      setEditProfileOpen(true);
                    }}>
                    Edit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </div>
      <EditProfie editProfileOpen={editProfileOpen} setEditProfileOpen={setEditProfileOpen} />

      {openAvatarModal && (
        <UpdateAvatar
          setIsAvatarModalOpen={setOpenAvatarModal}
          handleAvatarSelected={handleAvatarSelected}
          selectedAvatar={selectedAvatar}
          setSelectedAvatar={setSelectedAvatar}
          selectedAvatarFromDb={selectedAvatarFromDb}
        />
      )}
      <PasswordConfirmationDialog
        modalState={showPasswordModal}
        handleClose={handleClosePasswordModal}
      />
    </React.Fragment>
  );
};

export default AccountDetails;
