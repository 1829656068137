import React from 'react';
import cn from 'classnames';
import { CropperFade } from 'react-advanced-cropper';
import { getAbsoluteZoom, getZoomFactor } from 'advanced-cropper/extensions/absolute-zoom';
import Navigation from './Navigation/Navigation.jsx';
import PropTypes from 'prop-types';



export const CustomWrapper = ({ cropper, children, loaded, className }) => {
    const state = cropper.getState();
    const settings = cropper.getSettings();
    const absoluteZoom = getAbsoluteZoom(state, settings);
    const onZoom = (value, transitions, dir) => {
        if (Number(absoluteZoom.toFixed(2) <= 1)) {
            const val = (value > 1 && dir == "min") ? 1 : (value < 0 && dir == "plus") ? 1 : value
            cropper.zoomImage(getZoomFactor(state, settings, val), {
                transitions: !!transitions,
            });
        }
    };
    return (
        <CropperFade className={cn('custom-wrapper', className)} visible={state && loaded}>
            {children}
            <Navigation className="custom-wrapper__navigation" zoom={Number(absoluteZoom.toFixed(2))} onZoom={onZoom} />
        </CropperFade>
    );
};
CustomWrapper.propTypes = {
    cropper: PropTypes.any,
    children: PropTypes.any,
    loaded: PropTypes.any,
    className: PropTypes.any
};