import React, { useEffect, useState } from 'react';
import { Button, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import { roles } from '../../../utils/common';
import Modal from '../../../components/Modal';
import CustomTable from '../../../components/CustomTable';
import CapsyncIcon from '../../../components/CapsyncIcon';
import CheckBox from '../../../components/FormFields/CheckBox';
import ChooseUserModal from '../../Settings/components/ChooseUserModal';
import DeleteModal from '../../../components/DeleteModal';

// API
import {
  getAllProfessionalUsers,
  getCompanyAccess,
  removeAccess,
  removeProfessionalAccess
} from '../../../slices/clientSlice';
import { socket } from '../../../config/Socket';

/* ============================== MANAGE INDIVIDUAL CLIENT ============================== */
const ManageIndividualClientModel = ({
  handleCloseModel,
  userId,
  handleAddMoreClient,
  selectedData,
  fromTable
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const role = user && user.user_role.role;

  const [clientsData, setClientsData] = useState([]);
  const [selectedNewData, setSelectedNewData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isIndividualClient, setIsIndividualClient] = useState('');
  const [getClientList, setGetClientList] = useState(false);
  const [assignBeforeDelete, setAssignBeforeDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState({
    removeUser: false,
    moveUser: false
  });
  useEffect(() => {
    const uniqueUserIds = selectedData?.company_details?.reduce((acc, company) => {
      company?.firm_company_access?.forEach((access) => {
        if (!acc.includes(access.user_id)) {
          setShowWarning(true);
          acc.push(access.user_id);
        }
      });
      return acc;
    }, []);

    setSelectedNewData(uniqueUserIds);
    getAllClientList();
    return () => {
      setGetClientList(false);
    };
  }, [getClientList]);

  useEffect(() => {
    socket.on('sync_client_access_list', () => {
      getAllClientList();
    });

    return () => {
      socket.off('sync_client_access_list', () => {
        getAllClientList();
      });
    };
  }, []);

  const getAllClientList = () => {
    dispatch(
      fromTable === 'individualClient' ? getAllProfessionalUsers(userId) : getCompanyAccess(userId)
    )
      .unwrap()
      .then((res) => {
        if (res) {
          setClientsData(res?.data);
        }
      });
  };

  const handleSelectedUser = (key) => {
    if (key === 'all') {
      const filteredClients = clientsData.filter(
        (client) => !(client.role === 'Master Admin' && role === 'Admin')
      );
      if (filteredClients.length === selectedUsers.length) {
        setSelectedUsers([]);
      } else {
        setSelectedUsers(
          filteredClients.map((client) => {
            return fromTable === 'individualClient'
              ? selectedNewData?.includes(client.id)
                ? null
                : client.id
              : client.user_id;
          })
        );
      }
    } else {
      const index = selectedUsers.indexOf(key);
      if (index !== -1) {
        setSelectedUsers([...selectedUsers.slice(0, index), ...selectedUsers.slice(index + 1)]);
      } else {
        setSelectedUsers([...selectedUsers, key]);
      }
    }
  };
  const COLUMNS = [
    {
      label: '',
      renderCell: (item) =>
        role === roles?.standard?.name ? (
          ''
        ) : (
          <CheckBox
            name="select-header"
            handleChange={() =>
              handleSelectedUser(fromTable === 'individualClient' ? item.id : item.user_id)
            }
            values={{
              'select-header':
                selectedUsers.indexOf(fromTable === 'individualClient' ? item.id : item.user_id) >
                -1
            }}
            disabled={
              role === 'Standard' ||
              (item.role === 'Master Admin' && role === 'Admin') ||
              selectedNewData?.includes(item?.id)
            }
          />
        ),
      width: '30'
    },
    {
      label: 'Name',
      renderCell: (item) => item.display_name,
      width: '210'
    },
    {
      label: 'Role',
      renderCell: (item) => item.role,
      width: '210'
    },
    {
      label: '',
      renderCell: (item) =>
        role === roles?.standard?.name ? (
          ''
        ) : (
          <div className="cs-table-icons">
            <span
              onClick={() => {
                if (
                  (item.role !== 'Master Admin' || role === 'Master Admin') &&
                  role !== 'Standard' &&
                  !selectedNewData?.includes(item?.id)
                ) {
                  handleDeleteUser(fromTable === 'individualClient' ? item.id : item.user_id);
                }
              }}
              className={`cursor-pointer delete-outlined cs-delete-btn ${(role === 'Admin' && item.role === 'Master Admin') || role === 'Standard' || selectedNewData?.includes(item?.id) ? 'cs-disabled' : ''}`}>
              <CapsyncIcon title="delete-outlined" size="14" />
            </span>
          </div>
        )
    }
  ];

  const handleDeleteUser = (id) => {
    onHandleOpenModal('removeUser');
    setSelectedUsers([id]);
  };

  const onHandleCloseModal = (key) => {
    if (key === 'moveUser') {
      setAssignBeforeDelete(false), setIsIndividualClient('');
    }
    setIsModelOpen({
      ...isModelOpen,
      [key]: false
    });
  };

  const onHandleOpenModal = (key) => {
    setIsModelOpen({
      ...isModelOpen,
      [key]: true
    });
    if (key === 'moveUser') {
      setIsIndividualClient('viewAndManage');
    }
  };

  const onRemoveUserClick = async () => {
    setLoading(true)
    try {
      if (fromTable === 'individualClient') {
        await dispatch(
          removeProfessionalAccess({
            client_id: userId.toString(),
            remove_access_from: selectedUsers.map((id) => id.toString())
          })
        )
          .unwrap()
          .then((res) => {
            if (res.code === 200) {
              toast.success(res.message);
              getAllClientList();
              setSelectedUsers([]);
              setAssignBeforeDelete(false);
            }
          });
        setIsModelOpen({
          ...isModelOpen,
          removeUser: false,
          moveUser: false
        });
      }
      if (fromTable === 'companyClient') {
        await dispatch(
          removeAccess({
            company_id: userId.toString(),
            user_ids: selectedUsers.map((id) => id)
          })
        )
          .unwrap()
          .then((res) => {
            if (res.code === 200) {
              toast.success(res.message);
              getAllClientList();
              setSelectedUsers([]);
              setAssignBeforeDelete(false);
            }
          });
        setIsModelOpen({
          ...isModelOpen,
          removeUser: false,
          moveUser: false
        });
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  };

  const handleAssignBeforeDelete = () => {
    setAssignBeforeDelete(true);
    onHandleOpenModal('moveUser');
  };
  return (
    <React.Fragment>
      {!isModelOpen.removeUser && (
        <Modal
          title={role === roles?.standard?.name ? 'View Users' : 'View & Manage Users'}
          show={true}
          className="cs-md-modal cs-new-user-modal cs-common-modal-length"
          handleClose={handleCloseModel}
          body={
            <div className="view-and-manage-container">
              <div className="view-and-manage-header">
                <span className="cs-regular-h5 cs-neutral-80">List of the users</span>
                {selectedUsers.length ? (
                  <div className="cs-table-user-action">
                    <NavDropdown
                      title={
                        <span className="cursor-pointer cs-neutral-80">
                          <CapsyncIcon title="option-vertical-filled" size="18" />
                        </span>
                      }
                      className="cs-relative cs-dropdown-nav">
                      <NavDropdown.Item onClick={() => onHandleOpenModal('removeUser')}>
                        Delete
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                ) : null}
              </div>
              <CustomTable
                className="cs-checkbox-table"
                columns={COLUMNS}
                data={clientsData}
                isPaginate={false}
                totalRecords={0}
              />

              {role !== roles?.standard?.name && (
                <Button
                  onClick={() => onHandleOpenModal('moveUser')}
                  disabled={role === 'Standard'}
                  className="cs-btn-icon-tertiary cs-regular-h5">
                  <span className="add-filled cs-primary icon cs-icon">
                    <CapsyncIcon title="add-filled" size="14" />
                  </span>
                  <span>Add Users</span>
                </Button>
              )}
              {role !== roles?.standard?.name && showWarning && (
                <div className="cs-modal-text cs-light-body-text-m cs-neutral-80">
                  <strong className="cs-primary">Note:</strong> Disabled items cannot be removed
                  because the individual user is associated with a company
                </div>
              )}
            </div>
          }
          isCloseButton={false}
          saveButtonLabel={role === roles?.standard?.name ? 'Okay' : ' Save'}
          cancelButtonLabel={role === roles?.standard?.name ? null : 'Close'}
          handleOnSave={handleCloseModel}
          handleOnCancel={handleCloseModel}
        />
      )}
      {isModelOpen.moveUser && (
        <ChooseUserModal
          handleCloseModel={() => onHandleCloseModal('moveUser')}
          userId={userId}
          type="ManageIndividualClientModel"
          clientIds={selectedUsers}
          clientsData={clientsData}
          isIndividualClient={isIndividualClient}
          setGetClientList={setGetClientList}
          fromTable={fromTable}
          assignBeforeDelete={assignBeforeDelete}
          setAssignBeforeDelete={setAssignBeforeDelete}
          onRemoveUserClick={onRemoveUserClick}
        />
      )}
      {isModelOpen.removeUser && (
        <DeleteModal
          show={isModelOpen.removeUser}
          onHandleClose={() => onHandleCloseModal('removeUser')}
          onHandleConfirm={
            clientsData?.length === selectedUsers?.length || clientsData?.length === 1
              ? handleAssignBeforeDelete
              : onRemoveUserClick
          }
          deleteBodyText={
            clientsData?.length === selectedUsers?.length || clientsData?.length === 1
              ? 'You are about to delete this user, but before deleting this user, you need to re-assign clients. By hitting continue, you can move forward.'
              : 'You are going to delete this entry, Once it is deleted, you will be not able to recover this data'
          }
          deleteButtonText={
            clientsData?.length === selectedUsers?.length || clientsData?.length === 1
              ? 'Continue'
              : 'Delete'
          }
          loading={loading}
        />
      )}
    </React.Fragment>
  );
};

// PROPS TYPE
ManageIndividualClientModel.propTypes = {
  handleCloseModel: PropTypes.func,
  selectedData: PropTypes.object,
  userId: PropTypes.number,
  handleAddMoreClient: PropTypes.func,
  fromTable: PropTypes.string
};

export default ManageIndividualClientModel;
