import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import successVerification from '../../../assets/images/icons/success-verification.svg';

/* ============================== PASSWORD CONFIRMATION ============================== */
const PasswordConfirmationDialog = (props) => {
  const { modalState, handleClose } = props;
  return (
    <Modal
      title={'Reset Password'}
      isCloseButton={false}
      saveButtonLabel="Continue"
      show={modalState}
      handleOnSave={handleClose}
      className="cs-md-modal"
      modalFooterClass="cs-center-btn"
      btnSize="m-0 md-btn"
      body={
        <div className="cs-validation-popup">
          <Image src={successVerification} alt="Success verification" width={84} height={84} />
          <p className="cs-regular-body-text-m cs-neutral-100">
            Reset password link has been sent to your registration email address.
          </p>
        </div>
      }
    />
  );
};

// PROPS TYPE
PasswordConfirmationDialog.propTypes = {
  modalState: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default PasswordConfirmationDialog;
