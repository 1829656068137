import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import InputBox from '../../../components/FormFields/InputBox';
import SelectBox from '../../../components/FormFields/SelectBox';
import CONFIG from '../../../constants/config';
import { stepOneValidationSchema } from './Validation';
import { firmInquiry, resetForm, useUserID } from '../../../slices/authSlice';
import { COMPANY_TYPE_OPTIONS } from '../../../constants/system';
import { createDropdownArray, createDropdownObject } from '../../../utils/system';
import DatePickerBox from '../../../components/FormFields/DatePickerBox';
import CommonSpinner from '../../../components/CommonSpinner';

const StepOne = ({ nextStep, isReview }) => {
  const dispatch = useDispatch();
  const {
    status: authStatus,
    message,
    businessFormData: { step1 }
  } = useSelector((state) => state.auth);
  const userId = useUserID();
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    firm_name: '',
    business_type: '',
    incorporation_date: '',
    ein_number: ''
  });

  useEffect(() => {
    if (step1 && Object.keys(step1).length) {
      setInitialValues({
        ...step1,
        business_type: createDropdownObject(step1.business_type)
      });
    }
  }, [step1]);

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setStatus } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: stepOneValidationSchema,
      onSubmit: async (values) => {
        setLoading(true)
        try {
          const formData = {
            user_id: Number(userId),
            firm_name: values.firm_name,
            inquiry_step: 1,
            business_type: values.business_type.value,
            incorporation_date: values.incorporation_date,
            ein_number: values.ein_number
          };
          if (JSON.stringify(formData) === JSON.stringify(step1)) {
            nextStep();
            return;
          }
          await dispatch(firmInquiry(formData))
            .unwrap()
            .then((response) => response.status && nextStep())
            .catch((error) => setStatus({ error: error.message }));

        } catch (error) {
          console.log('error', error)
        } finally {
          setLoading(false)
        }
      }
    });

  useEffect(() => {
    if (authStatus === CONFIG.STATUS.ERROR) {
      setStatus({ error: message });
    }
  }, [authStatus, message, setStatus]);

  useEffect(() => {
    if (values) {
      dispatch(resetForm());
    }
  }, [values, dispatch]);

  return (
    <div className="cs-auth-form">
      <Form onSubmit={handleSubmit}>
        <div className="auth-sign-up-scrollbar">
          <Row>
            <Col lg={12}>
              <InputBox
                name="firm_name"
                values={values}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                autoComplete="off"
                label="Company name"
                disabled
                maxLength={5}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <SelectBox
                value={values.business_type}
                errors={errors}
                touched={touched}
                handleChangeSelect={(option) => setFieldValue('business_type', option)}
                options={createDropdownArray(COMPANY_TYPE_OPTIONS)}
                placeholder="Select your company type"
                name="business_type"
                label="Company type"
                disabled={isReview}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <InputBox
                name="ein_number"
                values={values}
                errors={errors}
                touched={touched}
                onChange={(e) => {
                  let value = e.target.value;
                  value = value.replace(/[^\d-]/g, '');
                  if (value?.length > 2 && value.indexOf('-') === -1) {
                    value = `${value.slice(0, 2)}-${value.slice(2)}`;
                  }
                  handleChange({ target: { name: "ein_number", value } });
                }}
                autoComplete="off"
                label="EIN number"
                disabled={isReview}
                maxLength={10}
              />
            </Col>
            <Col lg={6}>
              <DatePickerBox
                label="Incorporation date"
                name="incorporation_date"
                values={values}
                onChange={(date) => setFieldValue('incorporation_date', date)}
                maxDate={new Date()}
                errors={errors}
                touched={touched}
                disabled={isReview}
              />
            </Col>
          </Row>
          {errors && (
            <Row>
              <Col className="text-start">
                <span className="cs-light-body-text-m cs-danger">{errors.error}</span>
              </Col>
            </Row>
          )}
        </div>
        {!isReview && (
          <Row>
            <Col>
              <div className="cs-modal-btn cs-center-btn">
                <Button disabled={loading} type="submit" className="cs-btn-primary lg-btn cs-regular-h5">
                  Next
                  {loading && (
                    <CommonSpinner
                      classParent="cs-modal-common-spinner cs-neutral-60"
                      size={14}
                    />
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};

// PROPS TYPE
StepOne.propTypes = {
  nextStep: PropTypes.func.isRequired,
  isReview: PropTypes.bool.isRequired
};

export default StepOne;
