import * as CryptoJS from 'crypto-js';
import Cookies from 'universal-cookie';

// COMPONENT IMPORTS
import { SIDE_BAR_ITEMS } from '../constants/system';

const getValidAddress = (placesData) => {
  const address = { line1: '', line2: '', city: '', state: '', country: '', postal_code: '' };
  const isValid = {
    street_number: false,
    city: false
  };
  placesData.forEach((component) => {
    if (component.types.includes('street_number')) {
      address['line1'] = component.long_name;
      isValid.street_number = true;
    }
    if (component.types.includes('route')) {
      address['line1'] += ' ' + component.long_name;
    }
    if (component.types.includes('subpremise')) {
      address['line2'] = component.short_name;
    }
    if (component.types.includes('locality')) {
      address['city'] = component.long_name;
      isValid.city = true;
    } else if (component.types.includes('sublocality') && !address['city']) {
      address['city'] = component.long_name;
      isValid.city = true;
    }
    if (component.types.includes('administrative_area_level_1')) {
      address['state'] = component.long_name;
    }
    if (component.types.includes('administrative_area_level_1')) {
      address['state_code'] = component.short_name;
    }
    if (component.types.includes('country')) {
      address['country'] = component.short_name;
    }
    if (component.types.includes('postal_code')) {
      address['postal_code'] = component.short_name;
    }
  });
  if (!isValid.city) {
    return {
      error: 'Address must have city.',
      address
    };
  } else if (!isValid.street_number) {
    return {
      error: 'Address must have street number.',
      address
    };
  }
  return address;
};

const createDropdownArray = (str) => {
  const array = [];
  str.map((item) => {
    item && array.push({ value: item.replace(/ /g, '_').toLowerCase(), label: item });
  });
  return array;
};

const createDropdownObject = (str) => {
  return (
    str && {
      value: str.replace(/\s+/g, '_').toLowerCase(),
      label: str.charAt(0).toUpperCase() + str.slice(1).replace(/_/g, ' ')
    }
  );
};

const compareObject = (objA, ObjB) => {
  return JSON.stringify(objA) === JSON.stringify(ObjB);
};

const encrypt = (text) => {
  return CryptoJS.AES.encrypt(text, process.env.REACT_APP_SECRET_KEY).toString();
};

const decrypt = (encryptedBase64) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedBase64, process.env.REACT_APP_SECRET_KEY);
  if (decrypted) {
    try {
      const str = decrypted.toString(CryptoJS.enc.Utf8);
      if (str.length > 0) {
        return str;
      } else {
        return 'error 1';
      }
    } catch (e) {
      return 'error 2';
    }
  }
  return 'error 3';
};

const contrast = (rgb1, rgb2) => {
  const lum1 = luminance(...rgb1);
  const lum2 = luminance(...rgb2);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
};

const luminance = (r, g, b) => {
  const RED = 0.2126;
  const GREEN = 0.7152;
  const BLUE = 0.0722;
  const GAMMA = 2.4;

  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, GAMMA);
  });
  return a[0] * RED + a[1] * GREEN + a[2] * BLUE;
};

const tooltipContent = (params, type) => {
  if (params && params.popper.state) {
    const options = params.popper.state.options.values_data;
    if (options === '') {
      return false;
    }
    if (type === 'spaces') {
      return /^\S*$/.test(options);
    }
    if (type === 'lowercase') {
      return /[a-z]/.test(options);
    }
    if (type === 'uppercase') {
      return /[A-Z]/.test(options);
    }
    if (type === 'number') {
      return /[0-9]/.test(options);
    }
    if (type === 'symbol') {
      return options && /[^A-Za-z0-9]/.test(options);
    }
    if (type === 'more_characters') {
      const store = '' + options;
      return store.length < 8 ? false : true;
    }
    if (type === 'less_characters') {
      const store = '' + options;
      return store.length > 20 ? false : true;
    }
  } else {
    return false;
  }
};

const localObjectClear = () => {
  const userData = window.localStorage.getItem('token');
  if (userData) {
    const tempPass = localStorage.getItem('tempPass');
    localStorage.clear();
    localStorage.setItem('tempPass', tempPass);
  }
};
export const deviceVerifyCookieStore = (id, deviceVerify) => {
  const cookies = new Cookies();
  if (deviceVerify) {
    cookies.set(`wlpdeviceAuth24Hr-${id}`, `${deviceVerify}`, {
      path: '/',
      expires: new Date(Date.now() + 86400000 * 30)
    });
  }
  return true;
};

// GEtT LIST OF OPTIONS OF SECTION OF SIDE BAR
const getSideBarOptions = (option) => {
  const sideBarOptionsList = SIDE_BAR_ITEMS.find((item) => item.path === option);
  const optionsArr = [sideBarOptionsList.path];
  sideBarOptionsList.subItems.map((item) => optionsArr.push(item.path));
  return optionsArr;
};

/**
 * @param {string} encryptedPassword - The encrypted password string that needs to be decrypted.
 * @return {string|Error} - The decrypted password if successful, or the error encountered during decryption.
 **/
function decryptFilePassword(encryptedPassword) {
  try {
    let data = atob(encryptedPassword, 'base64').toString('ascii');
    data = atob(data, 'base64').toString('ascii');
    data = atob(data, 'base64').toString('ascii');
    return data;
  } catch (err) {
    console.log('err', err);
    return err;
  }
}

export {
  getValidAddress,
  createDropdownObject,
  createDropdownArray,
  compareObject,
  encrypt,
  decrypt,
  contrast,
  tooltipContent,
  localObjectClear,
  getSideBarOptions,
  decryptFilePassword
};
