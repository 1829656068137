import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import AddressSearch from '../../../components/AddressSearch';
import InputBox from '../../../components/FormFields/InputBox';
import TextAreaBox from '../../../components/FormFields/TextAreaBox';
import CONFIG from '../../../constants/config';
import { stepThreeValidationSchema } from './Validation';
import { firmInquiry, resetForm, useUserID } from '../../../slices/authSlice';
import { compareObject, createDropdownObject } from '../../../utils/system';
import CommonSpinner from '../../../components/CommonSpinner';

const StepThree = (props) => {
  const { nextStep, prevStep, isReview } = props;

  const dispatch = useDispatch();
  const {
    status: authStatus,
    message,
    businessFormData: { step3 }
  } = useSelector((state) => state.auth);

  const [isAddressChange, setIsAddressChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addressObj, setAddressObj] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    postal_code: ''
  });
  const [initialValues, setInitialValues] = useState({
    is_registered_investor: '',
    street_name: '',
    city: '',
    state: '',
    zip_code: '',
    country: 'USA',
    description: '',
    website: ''
  });
  const [formError, setFormError] = useState(''); // Custom Error after form submit from API
  const userId = useUserID();

  useEffect(() => {
    step3 &&
      Object.keys(step3).length != 0 &&
      setInitialValues({
        ...initialValues,
        ...step3,
        is_registered_investor: createDropdownObject(step3.is_registered_investor),
        street_name: step3.address.line1,
        city: step3.address.city,
        state: step3.address.state,
        zip_code: step3.address.postal_code
      });
  }, [step3]);

  useEffect(() => {
    step3.address && setAddressObj(step3.address);
  }, [step3]);

  useEffect(() => {
    if (step3?.address === '') {
      setAddressObj({
        line1: '',
        line2: '',
        city: '',
        state: '',
        postal_code: ''
      });

      setInitialValues({
        street_name: '',
        city: '',
        state: '',
        zip_code: '',
        description: '',
        website: ''
      });
    }
  }, [step3]);

  const {
    values,
    errors,
    touched,
    status,
    handleChange,
    handleSubmit,
    setFieldValue,
    setStatus,
    setFieldTouched
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: stepThreeValidationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        if (
          !!isAddressChange &&
          !!status &&
          (!!status.street_name || !!status.city || !!status.state || !!status.zip_code)
        ) {
          return true;
        }
        const formData = {
          user_id: Number(userId),
          address: addressObj,
          description: values.description,
          website: values.website,
          inquiry_step: 3
        };
        if (compareObject(formData, step3)) {
          nextStep();
          return true;
        }
        await dispatch(firmInquiry(formData))
          .unwrap()
          .then((response) => response.status && nextStep())
          .catch((error) => console.log('error', error.message));
      } catch (error) {
        console.log('error :>> ', error);
      } finally {
        setLoading(false)
      }
    }
  });

  useEffect(() => {
    authStatus === CONFIG.STATUS.ERROR && setFormError(message);
  }, [authStatus]);

  useEffect(() => {
    if (formError != '') {
      setFormError('');
      dispatch(resetForm());
    }
  }, [values]);

  return (
    <div className="cs-auth-form">
      <Form onSubmit={handleSubmit}>
        <div className="auth-sign-up-scrollbar">
          <AddressSearch
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setAddressObj={setAddressObj}
            setStatus={setStatus}
            status={status}
            addressObj={addressObj}
            setIsAddressChange={setIsAddressChange}
            isCountryField={true}
            setFieldTouched={setFieldTouched}
            disabled={isReview}
            step3={step3}
          />
          <Row>
            <Col lg={12}>
              <InputBox
                name="website"
                values={values}
                errors={errors}
                touched={touched}
                onChange={handleChange}
                autoComplete="off"
                label="Website"
                disabled={isReview}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="last-form-field resp-last-field">
                <TextAreaBox
                  name="description"
                  label="Description of services provided"
                  values={values}
                  onChange={handleChange}
                  disabled={isReview}
                  errors={errors}
                  touched={touched}
                />
              </div>
            </Col>
          </Row>
          {/* API ERROR MESSAGE DISPLAY */}
          <Row>
            <Col className="text-start">
              <span className="cs-light-body-text-m cs-danger">{formError}</span>
            </Col>
          </Row>
        </div>
        {!isReview && (
          <Row>
            <Col>
              <div className="cs-modal-btn cs-center-btn">
                <Button disabled={loading} className="cs-btn-secondary lg-btn cs-regular-h5" onClick={prevStep}>
                  Back
                </Button>
                <Button disabled={loading} type="submit" className="cs-btn-primary lg-btn cs-regular-h5">
                  Next
                  {loading && (
                    <CommonSpinner
                      classParent="cs-modal-common-spinner cs-neutral-60"
                      size={14}
                    />
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};

// PROPS TYPE
StepThree.propTypes = {
  nextStep: PropTypes.func,
  prevStep: PropTypes.func,
  isReview: PropTypes.bool
};

export default StepThree;
