/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Image, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import { useFormik } from 'formik';
import * as yup from 'yup';

// COMPONENT IMPORTS
import AuthBanner from '../AuthBanner';
import { socket } from '../../config/Socket';
import CommonSpinner from '../../components/CommonSpinner';
import InputBox from '../../components/FormFields/InputBox';
import Logo from '../../assets/images/brand/capsync-logo.svg';
import { emailField, signInPasswordField } from '../../validations';

// HOOKS IMPORTS
import useRemoveServerError from '../../hooks/useRemoveServerError';

// API
import {
  userSignIn,
  setMFATrue,
  resendEmailVerification,
  resetForm,
  useApiStatus
} from '../../slices/authSlice';
import { cryptoJsEncryption } from '../../utils/common';
import { useBrandDetails } from '../../slices/brandDetailSlice';

/* ============================== SIGN IN ============================== */
const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const brandDetails = useBrandDetails();
  useRemoveServerError();

  const apiStatus = useApiStatus();
  const cookies = new Cookies();

  const [isUserVerified, setIsUserVerified] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const initialValues = {
    email: '',
    password: ''
  };

  const validationSchema = yup.object().shape({
    email: emailField(),
    password: signInPasswordField()
  });

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const dataObj = { ...values };
      dataObj.password = await cryptoJsEncryption(values.password);
      const response = await dispatch(userSignIn(dataObj)).unwrap();
      if (response?.status) {
        socket.emit('updateSocket', { user_id: response?.data?.loginUserData?.id });
        setMessage(response?.message);
      }
      response?.status && setIsUserVerified(response?.data?.is_verified);
      if (response?.status && response?.data?.is_verified !== false) {
        if (response?.data?.loginUserData?.redirect_link) {
          navigate(response?.data?.loginUserData?.redirect_link);
          return;
        }
        const deviceCookie = cookies.get(`wlpdeviceAuth24Hr-${response?.data?.loginUserData?.id}`);
        if (deviceCookie) {
          dispatch(setMFATrue());
          navigate('/dashboard');
        } else {
          navigate('/mfa-setup');
        }
      }

      if (!response.status) {
        setMessage(response.message);
      }
    } catch (error) {
      console.log('error :>> ', error);
    } finally {
      setLoading(false);
    }
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });

  const handleResendEmail = () => {
    dispatch(resendEmailVerification({ email: values.email }));
    dispatch(resetForm());
    setIsUserVerified(null);
  };

  useEffect(() => {
    if (message) {
      setMessage('');
    }
  }, [values]);

  return (
    <section className="auth-section sign-in-section">
      <Container fluid>
        <Row className="align-items-center">
          <AuthBanner />
          <Col lg={6}>
            <div className="cs-right-section">
              <div className="cs-auth-header">
                <Image
                  className="cs-logo"
                  width="132"
                  src={
                    brandDetails && brandDetails?.is_domain_verified === true
                      ? brandDetails.primary_logo_s3_url
                      : Logo
                  }
                  alt="Capsync Logo"
                />
                <div className="cs-title">
                  <h1 className="cs-semi-bold-h1">Sign in</h1>
                </div>
              </div>
              <div className="cs-auth-form">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <InputBox
                        type="email"
                        name="email"
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        placeholder="Email address"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="last-form-field resp-last-field">
                        <InputBox
                          name="password"
                          type="password"
                          placeholder="Password"
                          values={values}
                          errors={errors}
                          touched={touched}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="text-end">
                      <Link className="cs-primary cs-regular-sub-heading-m" to="/forgot-password">
                        {' '}
                        Forgot password?
                      </Link>
                    </Col>
                  </Row>
                  {!errors['password'] && !errors['email'] && (
                    <React.Fragment>
                      <Row>
                        <Col className="text-center">
                          <span
                            className={
                              'cs-light-body-text-m ' +
                              (apiStatus === 'success' && isUserVerified !== false
                                ? 'cs-success'
                                : 'cs-danger')
                            }>
                            {message}
                          </span>
                        </Col>
                      </Row>
                      {isUserVerified === false && message && apiStatus === 'success' && (
                        <Row>
                          <Col className="text-center">
                            <span
                              className="cs-primary cs-regular-sub-heading-m cursor-pointer"
                              onClick={handleResendEmail}>
                              Resend email
                            </span>
                          </Col>
                        </Row>
                      )}
                    </React.Fragment>
                  )}
                  <Row>
                    <Col>
                      <div className="auth-footer">
                        <Button
                          type="submit"
                          disabled={loading}
                          className="cs-btn-primary lg-btn cs-regular-h5">
                          {' '}
                          Sign in
                          {loading && (
                            <CommonSpinner
                              classParent="cs-modal-common-spinner cs-neutral-60"
                              size={14}
                            />
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
                <span className="auth-main-link cs-regular-body-text-m">
                  New to CapSync?
                  <Link to="/sign-up" className="cs-primary">
                    &nbsp;Sign up
                  </Link>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SignIn;
