import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

// COMPONENT IMPORTS
import axios from '../config/api';

// BRANDING API CALL
export const getBrandDetailsById = createAsyncThunk('auth/firm_brand', async (id) => {
  return await axios.get(`firm_brand/get_brand_details_by_firm_id/${id}`);
});

// INITIAL STATE
const initialState = {
  brandDetails: JSON.parse(window.localStorage.getItem('brand-details')) || {}
};

/* ============================== BRANDING SLICE ============================== */
const brandDetailSlice = createSlice({
  name: 'brandDetail',
  initialState,
  reducers: {
    clearBrandingDetails: (state) => {
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrandDetailsById.fulfilled, (state, { payload }) => {
        const { status, data } = payload;
        if (status) {
          window.localStorage.setItem('brand-details', JSON.stringify(data));
          state.brandDetails = data;
        } else {
          state.brandDetails = {};
        }
      })
      .addCase(getBrandDetailsById.rejected, (state) => {
        state.brandDetails = {};
      });
  }
});
export const { clearBrandingDetails } = brandDetailSlice.actions;
export default brandDetailSlice.reducer;

const selectBrandDetails = (state) => state.brandDetail.brandDetails;
export const useBrandDetails = () => {
  const brandDetails = useSelector(selectBrandDetails);
  return useMemo(() => brandDetails, [brandDetails]);
};
