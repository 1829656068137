import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import config from '../../../constants/config';
import { TOAST_MESSAGE } from '../../../constants/message';
import SearchBox from '../../../components/FormFields/SearchBox';
import CapsyncToolTip from './../../../components/CapsyncToolTip';

// HOOKS IMPORTS
import useDebounce from '../../../hooks/useDebounce';

// API
import {
  addClientAccess,
  assignCompanyAccess,
  clearMessage,
  reAssignClient,
  reassignClients,
  resetError
} from '../../../slices/clientSlice';
import {
  allUsersList,
  deleteCollabUser,
  moveCollabUserClients
} from '../../../slices/collaborationSlice';

/* ============================== CHOOSE USER MODAL ============================== */
const ChooseUserModal = ({
  onHandleConfirm,
  handleCloseModel,
  clientsData = [],
  manageClientModelId,
  userId,
  type,
  clientIds = [],
  isIndividualClient,
  setGetClientList,
  deleteUser = false,
  setSelectedUsers,
  fromTable,
  onRemoveUserClick,
  assignBeforeDelete = false,
  setAssignBeforeDelete
}) => {
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(-1);
  const [errorDelete, setErrorDelete] = useState('');
  const dispatch = useDispatch();
  const { message, status } = useSelector((state) => state.client);
  const [showStatus, setShowStatus] = useState(false);
  useEffect(() => {
    getAllCollaborationUsersList({
      isPaginate: false,
      include_logged_user: true,
      // is_assign_list: true
      exclude_user_id: type == 'TeamCollaboration' ? manageClientModelId : null
    });
  }, []);
  const getAllCollaborationUsersList = (params) => {
    dispatch(allUsersList(params))
      .unwrap()
      .then((res) => {
        if (res && res.records) {
          // Filtered the unverified users
          const verifiedUsers = res?.records.filter(
            (user) => user?.email_verification_status && user?.is_profile_set
          );

          const newVerifiedUsers = verifiedUsers.filter(
            (user) =>
              !clientsData?.some((client) => {
                return client.email === user.email;
              })
          );
          if (type === 'ManageIndividualClientModel') {
            setUsersList(newVerifiedUsers);
          } else {
            setUsersList(verifiedUsers);
          }
        }
      });
  };

  useEffect(() => {
    if (showStatus) {
      if (status === config.STATUS.SUCCESS) {
        handleCloseModel();
        toast.success(TOAST_MESSAGE.USER_ADDED_SUCCESS);
        if (assignBeforeDelete) {
          onRemoveUserClick();
          setAssignBeforeDelete(false);
        }
        if (isIndividualClient === 'viewAndManage' && selectedUserId !== -1) {
          setGetClientList(true);
          setSelectedUserId(-1);
        }
        if (isIndividualClient === 'IndividualTable' && selectedUserId !== -1) {
          setSelectedUserId(-1);
          setSelectedUsers([]);
        }
      } else {
        status === config.STATUS.ERROR;
      }
    }
    return () => {
      setShowStatus(false);
    };
  }, [showStatus]);

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
    onHandleSearchData(e.target.value);
  };

  const onHandleSearchData = useDebounce((params) => {
    getAllCollaborationUsersList({
      search: params,
      isPaginate: false,
      exclude_user_id: type == 'TeamCollaboration' ? manageClientModelId : null
      // is_assign_list: true
    });
  }, 700);

  const onSaveClick = async () => {
    setLoading(true);
    try {
      if (deleteUser) {
        const payload = {
          individual_ids: clientIds, // list of individuals ID which will be re-assigned
          assignee_id: selectedUserId // Selected FA where all clients will be re-assigned
        };
        const response = await dispatch(reassignClients(payload)).unwrap();
        if (response) {
          onHandleConfirm();
          handleCloseModel();
        }
        setLoading(false);
      } else {
        if (clientIds?.length) {
          const moveResponse = await dispatch(
            moveCollabUserClients({
              transfer_from: userId.toString(),
              transfer_users: clientIds.map((id) => id.toString()),
              assign_to: selectedUserId.toString()
            })
          );
          if (moveResponse) {
            handleCloseModel();
            setShowStatus(true);
          }
          setLoading(false);
        } else {
          const deleteResponse = await dispatch(
            deleteCollabUser({
              professional_user_id: userId,
              assign_to: selectedUserId
            })
          ).unwrap();
          if (deleteResponse?.status) {
            handleCloseModel();
          } else {
            setErrorDelete(deleteResponse?.errors);
          }
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log('error :>> ', error);
    }
  };

  const handleIndividualUserAccess = () => {
    setLoading(true);
    if (
      isIndividualClient === 'viewAndManage' &&
      selectedUserId !== -1 &&
      fromTable === 'individualClient'
    ) {
      const data = {
        client_id: userId.toString(),
        professional_user_id: selectedUserId.toString()
      };
      dispatch(addClientAccess(data))
        .then(() => {
          setShowStatus(true);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    if (
      isIndividualClient === 'viewAndManage' &&
      selectedUserId !== -1 &&
      fromTable === 'companyClient'
    ) {
      const data = {
        company_id: userId.toString(),
        professional_user_id: selectedUserId.toString()
      };
      dispatch(assignCompanyAccess(data))
        .then(() => {
          setShowStatus(true);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    if (isIndividualClient === 'IndividualTable' && selectedUserId !== -1) {
      dispatch(
        reAssignClient({
          individual_ids: clientIds,
          assignee_id: selectedUserId
        })
      )
        .then(() => {
          setShowStatus(true);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const closeModalHandler = () => {
    setLoading(false);
    dispatch(resetError());
    handleCloseModel();
  };

  return (
    <Modal
      title="Choose user"
      className="cs-md-modal"
      body={
        <div className="choose-user-modal">
          <SearchBox
            label={`Choose user where you want to ${deleteUser ? 'remove' : 'move'} `}
            value={searchValue}
            placeholder={'Search by name'}
            onChange={onSearchChange}
          />

          {/* <Table className="cs-table choose-user-table">
            <thead>
              <tr>
                <th className="cs-neutral-60 cs-regular-body-text-m">Name</th>
                <th className="cs-neutral-60 cs-regular-body-text-m">Email</th>
              </tr>
            </thead>
            <tbody className="cs-neutral-100 cs-regular-body-text-m">
              {usersList && usersList.length
                ? usersList.map((user, i) => (
                    <tr
                      key={i}
                      className={selectedUserId === user.id ? 'table-active' : ''}
                      onClick={() => setSelectedUserId(user.id)}>
                      <td>{user.display_name}</td>
                      <td>{user.email}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table> */}
          {usersList && usersList?.length ? (
            <div className="cs-user-table">
              <Container>
                <Row>
                  <Col>
                    <span className="cs-neutral-90 cs-regular-h4">Name</span>
                  </Col>
                  <Col>
                    <span className="cs-neutral-90 cs-regular-h4">Email</span>
                  </Col>
                </Row>
              </Container>
              <div className="cs-user-td cs-regular-h4 cs-neutral-100">
                {usersList.map((user, i) => (
                  <Row
                    key={i}
                    onClick={() => {
                      setSelectedUserId(user.id);
                      dispatch(clearMessage());
                    }}
                    className={`${selectedUserId === user.id ? 'table-active' : ''}`}>
                    <Col xs={6}>
                      <span className="cs-text-ellipsis">{user.display_name}</span>
                    </Col>
                    <Col xs={6}>
                      <span className="cs-text-ellipsis">
                        {/* {user.email} */}
                        {user.email ? (
                          user.email.length > 25 ? (
                            <CapsyncToolTip
                              Child={user.email.slice(0, 25) + '...'}
                              placement={'top'}
                              message={user.email}
                              type="text"
                            />
                          ) : (
                            user.email
                          )
                        ) : (
                          '-'
                        )}
                      </span>
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
          ) : (
            <span className="cs-common-no-record cs-light-body-text-l cs-neutral-100">
              No data found
            </span>
          )}
          {status === 'error' && (
            <span className="form-error-msg cs-light-body-text-s cs-danger">{message}</span>
          )}
          {errorDelete && (
            <span className="form-error-msg cs-light-body-text-s cs-danger">{errorDelete}</span>
          )}
          {/* {searchValue === "" && usersList.length === 1 && type === 'ManageIndividualClientModel' && (
            <span className="form-error-msg cs-light-body-text-s cs-danger">
              The user already has account access of this individual user
            </span>
          )} */}
        </div>
      }
      isCloseButton={false}
      show={true}
      saveButtonLabel={'Save'}
      cancelButtonLabel={'Cancel'}
      handleOnSave={!isIndividualClient ? onSaveClick : handleIndividualUserAccess}
      handleOnCancel={closeModalHandler}
      disabled={selectedUserId < 0 || loading}
    />
  );
};

// PROPS TYPE
ChooseUserModal.propTypes = {
  handleCloseModel: PropTypes.func,
  userId: PropTypes.number,
  clientIds: PropTypes.array,
  onHandleConfirm: PropTypes.any,
  type: PropTypes.string,
  isIndividualClient: PropTypes.string,
  setGetClientList: PropTypes.func,
  deleteUser: PropTypes.bool,
  setSelectedUsers: PropTypes.func,
  fromTable: PropTypes.string,
  manageClientModelId: PropTypes.any,
  clientsData: PropTypes.any,
  onRemoveUserClick: PropTypes.func,
  assignBeforeDelete: PropTypes.bool,
  setAssignBeforeDelete: PropTypes.func
};

export default ChooseUserModal;
