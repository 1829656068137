import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

// COMPONENT IMPORTS
import AuthHead from './AuthHead';
import AuthBanner from './AuthBanner';
import { emailField } from '../validations';
import CapsyncIcon from '../components/CapsyncIcon';
import CommonSpinner from '../components/CommonSpinner';
import InputBox from '../components/FormFields/InputBox';

// HOOKS IMPORTS
import useRemoveServerError from '../hooks/useRemoveServerError';

// API
import { userForgotPassword } from '../slices/authSlice';
import { toast } from 'react-toastify';

/* ============================== FORGOT PASSWORD ============================== */
const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useRemoveServerError();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: yup.object().shape({
      email: emailField()
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await dispatch(userForgotPassword(values))
          .unwrap()
          .then((res) => {
            const message = res?.message;            
            if (res?.code === 200) {
              toast.success("We have emailed you a password reset link.")
              navigate('/');
            } else setError(message);
          });
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    }
  });

  useEffect(() => setError(''), [values]);

  return (
    <section className="auth-section auth-common-section">
      <Container fluid>
        <Row className="align-items-center">
          <AuthBanner />
          <Col lg={6}>
            <div className="cs-right-section forgot-password">
              <AuthHead
                title={'Forgot Password'}
                description={`Don't worry! Enter your email address below and we will email you a link to reset your password.`}
              />
              <div className="cs-auth-form">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <InputBox
                        type="email"
                        name="email"
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        placeholder="Email address"
                      />
                    </Col>
                  </Row>
                  {error && (
                    <span className="cs-regular-body-text-m cs-danger">
                      <CapsyncIcon title="declined-outlined" size="16" />
                      {error}
                    </span>
                  )}
                  <div className="auth-footer">
                    <Button
                      type="submit"
                      disabled={loading}
                      className="cs-btn-primary lg-btn cs-regular-h5">
                      Reset password
                      {loading && (
                        <CommonSpinner
                          classParent="cs-modal-common-spinner cs-neutral-60"
                          size={14}
                        />
                      )}
                    </Button>
                    <span className="back-to-sign-in">
                      <Button
                        variant="link"
                        onClick={() => navigate('/')}
                        className="cs-btn-tertiary lg-btn cs-regular-h5">
                        Back to sign in
                      </Button>
                    </span>
                  </div>
                </Form>
                <span className="auth-main-link cs-regular-body-text-m">
                  New to CapSync?
                  <Link to="/sign-up" className="cs-primary">
                    &nbsp;Sign up
                  </Link>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ForgotPassword;
