/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Image, Form, Button } from 'react-bootstrap';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

// COMPONENT IMPORTS
import {
  firstNameField,
  lastNameField,
  emailField,
  phoneField,
  passwordField,
  confirmPasswordField,
  companyNameField,
  strongWeakPasswordField
} from '../../validations';
import AuthBanner from '../AuthBanner';
import CapsyncIcon from '../../components/CapsyncIcon';
import CommonSpinner from '../../components/CommonSpinner';
import InputBox from '../../components/FormFields/InputBox';
import { usAreaCodes } from '../../config/countryAreaCodes';
import Logo from '../../assets/images/brand/capsync-logo.svg';
import PasswordRequirement from '../../pages/Settings/components/PasswordRequirement';

// HOOKS IMPORTS
import useRemoveServerError from '../../hooks/useRemoveServerError';

// API
import {
  addUsersProfile,
  emailVerification,
  setVerfiedToken,
  userSignUp
} from '../../slices/authSlice';
import { cryptoJsEncryption } from '../../utils/common';

/* ============================== SIGN UP ============================== */
const SignUp = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { token } = useParams();
  useRemoveServerError();

  const { userVerifiedDetails } = useSelector((state) => state.auth);

  const [showPwdRequirements, setShowPwdRequirements] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setValues } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        first_name: '',
        last_name: '',
        email: '',
        phone_no: '',
        password: '',
        confirm_password: '',
        firm_name: ''
      },
      validationSchema: yup.object().shape({
        first_name: firstNameField(),
        last_name: lastNameField(),
        email: emailField(),
        phone_no: phoneField(),
        firm_name: companyNameField(),
        password: strongWeakPasswordField(),
        confirm_password: confirmPasswordField()
      }),
      onSubmit: async (values) => {
        setLoading(true);
        try {
          const dataObj = { ...values };
          dataObj.password = await cryptoJsEncryption(values.password);
          dataObj.confirm_password = await cryptoJsEncryption(values.confirm_password);
          if (token && userVerifiedDetails.email && userVerifiedDetails.role) {
            await dispatch(
              addUsersProfile({
                id: userVerifiedDetails.user_id,
                details: {
                  password: dataObj.password,
                  confirm_password: dataObj.confirm_password,
                  phone_no: values.phone_no
                }
              })
            )
              .unwrap()
              .then((response) => {
                if (response.status) {
                  navigation('/thanks');
                } else {
                  setErrorMsg(response.message);
                }
              })
              .catch((error) => console.log('error', error.message));
          } else {
            // dispatch(userSignUp(values))
            await dispatch(userSignUp(dataObj))
              .unwrap()
              .then((response) => {
                if (response.status) {
                  navigation('/email-verification');
                } else {
                  setErrorMsg(response.message);
                }
              })
              .catch((error) => console.log('error', error.message));
          }
        } catch (error) {
          console.log('error', error);
        } finally {
          setLoading(false);
        }
      }
      // User Signup API call
    });
  const handlePhoneChange = (event) => {
    if (event) {
      if (
        isValidPhoneNumber(event) === true &&
        usAreaCodes.includes(Number(event.split('+1')[1].substr(0, 3))) &&
        event.split('+1')[1].length == 10
      ) {
        setFieldValue('phone_no', event);
      } else {
        setFieldValue('phone_no', event);
      }
    } else {
      setFieldValue('phone_no', '');
    }
  };

  const handleCursorPosition = (e) => {
    const input = e.target;
    const countryCodeLength = '+1 '.length;

    if (input.selectionStart <= countryCodeLength) {
      input.setSelectionRange(countryCodeLength, countryCodeLength);
    }
  };

  const onUserVerification = async () => {
    try {
      await dispatch(emailVerification(token))
        .unwrap()
        .then((res) => {
          if (res.status) {
            setValues({
              ...values,
              first_name: res.data.first_name,
              last_name: res.data.last_name,
              email: res.data.email,
              firm_name: res.data.firm_name
            });
            if (res?.data?.is_profile_set) {
              navigation('/');
            }
          }
        });
    } catch (error) {
      console.log('error');
    }
  };

  useEffect(() => {
    setErrorMsg('');
  }, [values]);

  useEffect(() => {
    if (token) {
      onUserVerification();
      dispatch(setVerfiedToken(token));
    }
  }, [token]);

  return (
    <section className="auth-section  sign-up-section">
      <Container fluid>
        <Row className="align-items-center">
          <AuthBanner />
          <Col lg={6}>
            <div className="cs-right-section">
              <div className="cs-auth-header">
                <Image className="cs-logo" width="132" src={Logo} alt="Capsync Logo" />
                <div className="cs-title">
                  <h1 className="cs-semi-bold-h1">Sign up</h1>
                </div>
              </div>
              <div className="cs-auth-form auth-sign-up-scrollbar">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={6}>
                      <InputBox
                        name="first_name"
                        placeholder="Enter first name"
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        disabled={token && userVerifiedDetails?.first_name}
                      />
                    </Col>
                    <Col lg={6}>
                      <InputBox
                        name="last_name"
                        placeholder="Enter last name"
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        disabled={token && userVerifiedDetails?.last_name}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <InputBox
                        name="email"
                        type="email"
                        placeholder="Email address"
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        disabled={token && userVerifiedDetails?.email}
                      />
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="cs-form-group">
                        <PhoneInput
                          limitMaxLength={15}
                          international
                          countryCallingCodeEditable={false}
                          placeholder="Phone Number"
                          name="phone_no"
                          addInternationalOption={false}
                          withCountryCallingCode={false}
                          value={values.phone_no}
                          className="form-control"
                          onChange={handlePhoneChange}
                          defaultCountry="US"
                          countries={['US']}
                          autoComplete="off"
                          numberInputProps={{ onSelect: handleCursorPosition }}
                        />
                        {errors.phone_no && touched.phone_no ? (
                          <span className="form-error-msg cs-light-body-text-s cs-danger">
                            {errors.phone_no}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <InputBox
                        name="firm_name"
                        type="text"
                        placeholder="Company name"
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        disabled={token && userVerifiedDetails?.firm_name}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <InputBox
                        name="password"
                        type="password"
                        placeholder="Password"
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        onFocus={() => setShowPwdRequirements(true)}
                        // onBlur={() => setShowPwdRequirements(false)}
                        showPwdRequirements={showPwdRequirements}
                        id="input-password-field"
                      />
                      {showPwdRequirements && (
                        <PasswordRequirement
                          password={values.password}
                          setShowPwdRequirements={setShowPwdRequirements}
                          inputFieldId="input-password-field"
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="last-form-field resp-last-field">
                        <InputBox
                          name="confirm_password"
                          type="password"
                          placeholder="Confirm password"
                          values={values}
                          errors={errors}
                          touched={touched}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                  {/* API ERROR MESSAGE DISPLAY */}
                  <Row>
                    {Object.keys(errors).length === 0 && errorMsg !== '' && (
                      <Col className="text-start">
                        <span className="cs-light-body-text-m cs-danger icon-error-msg">
                          <span className="icon">
                            <CapsyncIcon size="18" title="info-filled" />
                          </span>
                          <span>{errorMsg}</span>
                        </span>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    <Col>
                      <div className="auth-footer">
                        <Form.Group>
                          <div className="cs-regular-body-text-m">
                            <span
                              htmlFor="termsCheckbox"
                              className="cs-light-body-text-m cs-neutral-90">
                              By selecting Sign up with email, you agree to our&nbsp;
                              <Link
                                to="https://capsync.com/terms-of-service/"
                                className="cs-primary cs-regular-body-text-m cs-link"
                                target="_Blank">
                                Terms of Service
                              </Link>
                              &nbsp;and acknowledge our&nbsp;
                              <Link
                                to="https://capsync.com/privacy-policy/"
                                className="cs-primary cs-regular-body-text-m cs-link"
                                target="_Blank">
                                Privacy Policy.
                              </Link>
                            </span>
                          </div>
                        </Form.Group>
                        <Button
                          type="submit"
                          disabled={loading}
                          className="cs-btn-primary lg-btn cs-regular-h5">
                          Continue
                          {loading && (
                            <CommonSpinner
                              classParent="cs-modal-common-spinner cs-neutral-60"
                              size={14}
                            />
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
                <span className="auth-main-link cs-regular-body-text-m">
                  Already have a CapSync account?
                  <Link to="/" className="cs-primary">
                    {' '}
                    &nbsp;Sign in
                  </Link>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SignUp;
