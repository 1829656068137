import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserDetails } from '../slices/authSlice';
import { useDispatch } from 'react-redux';

/* ============================== MASTER ADMIN REDIRECTION ============================== */
const MasterAdminRedirect = () => {
    const { professionalUserId, token } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const getProfessionalUser = async () => {
        window.localStorage.setItem('token', token);
        const response = await dispatch(getUserDetails(`${professionalUserId}`)).unwrap();
        if (response?.status) {
            navigate("/dashboard")
        }
    }
    useEffect(() => {
        getProfessionalUser()
    }, [token])

    return <div className="cs-redirect"></div>;
};
export default MasterAdminRedirect;
