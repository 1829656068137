import * as React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { ToastContainer, Flip } from 'react-toastify';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import AppRoutes from './routes';

// CSS IMPORTS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-toastify/dist/ReactToastify.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-phone-number-input/style.css';

import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/dashboard.css';

// COMPONENT IMPORTS
import { socket } from './config/Socket';
import { localObjectClear } from './utils/system';
import UnderMaintenance from './components/UnderMaintenance';
import InactivityLogoutModal from './components/InactivityLogoutModal';
import default_favicon from './assets/images/icons/default-favicon.png';

// API
import { authUserLogout, getUserDetails, userSignOut, useUserID } from './slices/authSlice';
import { allCollaborationUsersList } from './slices/collaborationSlice';
import DeviceDetector from 'device-detector-js';
import {
  clearBrandingDetails,
  getBrandDetailsById,
  useBrandDetails
} from './slices/brandDetailSlice';

const ExcludeInactivityRoutes = [
  'sign-in',
  'sign-up',
  'verify-business',
  'mfa-setup',
  'verification-pin',
  'forgot-password',
  'authenticator-setup',
  'confirmed-business',
  'email-verification',
  'thank-you',
  'error-page'
];

const INACTIVITY_TIMER = process.env.REACT_APP_INACTIVITY_TIMEOUT
  ? process.env.REACT_APP_INACTIVITY_TIMEOUT * 1000 * 60
  : 1000 * 60 * 15;
const PROMPT_TIMER = process.env.REACT_APP_PROMPT_DISPLAY_TIMEOUT
  ? process.env.REACT_APP_PROMPT_DISPLAY_TIMEOUT * 60
  : 60 * 2;

var pjson = require('../package.json');
const version = process.env.REACT_APP_VERSION + '-' + pjson.commit_id;
console.log('App version & commit id:', version);
/* ============================== APP ============================== */
function App() {
  const isSocketInitialized = React.useRef(false);
  const userID = useUserID();
  const { user, mfa } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const brandDetail = useBrandDetails();

  const renderRoutes = React.useMemo(() => {
    const router = createBrowserRouter(AppRoutes);
    return <RouterProvider router={router} />;
  }, []);

  const [countdown, setCountdown] = React.useState(INACTIVITY_TIMER); // 2 mins
  const [sessionToken, setSessionToken] = React.useState(null); // 2 mins

  // Manage Inactivity time
  const { getRemainingTime, activate, isLastActiveTab } = useIdleTimer({
    // onIdle,
    // onActive,
    // onAction,
    timeout: INACTIVITY_TIMER, // use for total in activity timeout
    syncTimers: 200,
    startOnMount: true,
    throttle: 10000,
    // onPrompt: () => handleInactivityTimeout(),
    promptBeforeIdle: 1000 * PROMPT_TIMER, // timeout - promptBeforeIdle -> call onPrompt
    crossTab: true,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'touchstart',
      'touchmove'
      // 'visibilitychange'
    ]
  });

  const handleStay = () => {
    activate();
    // setShowModal(false);
  };

  const handleLogoutBroadCast = async () => {
    try {
      if (userID) {
        dispatch(authUserLogout());
        dispatch(clearBrandingDetails());
        localObjectClear();
      }
    } catch (error) {
      console.log('error', error)
    }
  };

  const handleLogoutUser = async () => {
    console.log("handleLogoutUser")
    const response = await dispatch(userSignOut()).unwrap();
    if (response) {
      dispatch(authUserLogout());
      dispatch(clearBrandingDetails());
      localObjectClear();
      window.location.reload();
    }
  };
  // brand details get
  const brandDetailsGet = async () => {
    try {
      await dispatch(getBrandDetailsById(user?.firm_id)).unwrap();
    } catch (error) {
      console.log('brandDetailsGet error :>> ', error);
    }
  };

  React.useEffect(() => {
    if (user && user?.firm_id) {
      brandDetailsGet();
    }
  }, [user]);

  const updateDocumentHead = (brandData, faviconUrl, title) => {
    const primaryColor = brandData ? brandData.primary_color : '#0024d4';
    document.documentElement.style.setProperty('--primary-main', primaryColor);
    document.title = title || (brandData ? brandData.firm_name : 'Firm Portal');

    const favicon = document.querySelector("link[rel='icon']") || document.createElement('link');
    favicon.href = faviconUrl || (brandData ? brandData.small_logo_s3_url : default_favicon);
    favicon.rel = 'icon';
    document.head.appendChild(favicon);
  };

  const restrictedRoutes = [
    'sign-up',
    'email-verification',
    'verify-business',
    'thank-you',
    'thanks',
    'confirmed-business'
  ];

  React.useEffect(() => {
    const currentPath = window.location.pathname;
    const pathnames = currentPath?.split('/');
    const isRestricted = restrictedRoutes.includes(pathnames[1]);
    if (
      isRestricted ||
      !(
        brandDetail &&
        Object.keys(brandDetail).length > 0 &&
        brandDetail?.is_domain_verified === true
      )
    ) {
      updateDocumentHead(null, default_favicon, 'Firm Portal');
    } else {
      updateDocumentHead(brandDetail, brandDetail?.small_logo_s3_url, brandDetail?.firm_name);
    }
  }, [brandDetail, window.location.pathname]);

  React.useEffect(() => {
    const wlUnauthorizedFun = () => {
      handleLogoutUser();
    };
    const handleRoleChange = () => {
      if (userID) dispatch(getUserDetails(userID));
      dispatch(
        allCollaborationUsersList({
          page: 1,
          limit: 10
        })
      );
    };

    socket.on('role_changed', handleRoleChange);
    socket.on('wl_unauthorized', wlUnauthorizedFun);
    socket.on('session_logout', (response) => {
      setSessionToken(response?.token);
      window.localStorage.setItem('socket_token', response?.token);
    });
    if (userID && !isSocketInitialized.current) {
      socket.emit('updateSocket', { user_id: userID });
      isSocketInitialized.current = true;
    }
    const handleLoad = () => {
      // Manage tab close session

      if (userID) {
        const lastTimeStamp = window.localStorage.getItem('lastTimeStamp');

        const duration = moment.duration(moment().diff(lastTimeStamp));
        const durationInMinutes = duration.asMinutes();

        const tabCloseTimeoutLimit = process.env.REACT_APP_TAB_CLOSE_TIMEOUT_LIMIT || 5;

        if (
          durationInMinutes &&
          tabCloseTimeoutLimit < durationInMinutes &&
          !window.location.pathname.startsWith('/individual/quickbooks/') &&
          !window.location.pathname.startsWith('/redirect/client/')
        ) {
          handleLogoutUser();
        }
      } else {
        window.localStorage.setItem('lastTimeStamp', '');
      }
    };
    const handleTabClose = (event) => {
      window.localStorage.setItem('lastTimeStamp', moment());
    };

    window.addEventListener('load', handleLoad);
    window.addEventListener('unload', handleTabClose);

    const interval = setInterval(() => {
      setCountdown(Math.ceil(getRemainingTime() / 1000));
    }, 1000);
    const logoutChannel = new BroadcastChannel('logout_channel');
    const handleLogoutMessage = (event) => {
      if (event.data === 'logout') {
        handleLogoutBroadCast();
      }
    };
    logoutChannel.addEventListener('message', handleLogoutMessage);
    return () => {
      window.removeEventListener('load', handleLoad);
      window.removeEventListener('unload', handleTabClose);
      socket.off('role_changed', handleRoleChange);
      socket.off('wl_unauthorized', wlUnauthorizedFun);
      socket.off('session_logout');
      clearInterval(interval);
      logoutChannel.removeEventListener('message', handleLogoutMessage);
      logoutChannel.close();
      // window.removeEventListener("blur", onBlur);
    };
  }, []);
  React.useEffect(() => {
    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    const device_info = deviceDetector.parse(userAgent);
    document.body.classList.add(
      `os-${device_info.os.name.replace(/ /g, "-").toLowerCase()}`
    );
    document.body.classList.add(
      `browser-${device_info.client.name.replace(/ /g, "-").toLowerCase()}`
    );
  }, []);
  React.useEffect(() => {
    const socketToken = window.localStorage.getItem('socket_token');
    if (
      user &&
      Object.keys(user).length > 0 &&
      socketToken &&
      socketToken !== user?.token
    ) {
      console.log('socketToken', socketToken)
      handleLogoutUser();
    }
  }, [sessionToken]);

  React.useEffect(() => {
    activate();
  }, [window.location.pathname]);

  if (process.env.REACT_APP_IS_UNDER_MAINTENANCE === 'true') {
    return <UnderMaintenance />;
  }

  return (
    <React.Fragment>
      {renderRoutes}
      <div className="cs-toast">
        <ToastContainer
          theme="light"
          position="top-right"
          autoClose={3000}
          pauseOnFocusLoss={false}
          newestOnTop
          hideProgressBar={true}
          transition={Flip}
        />
      </div>
      {!!!ExcludeInactivityRoutes.find(
        (route) => window.location.pathname.includes(route) || window.location.pathname === '/'
      ) &&
        isLastActiveTab() &&
        countdown <= PROMPT_TIMER && (
          <InactivityLogoutModal
            remainingSeconds={countdown}
            onStay={handleStay}
            handleLogoutUser={handleLogoutUser}
          />
        )}
    </React.Fragment>
  );
}

export default App;
