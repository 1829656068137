import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

/* ============================== PROTECTED ROUTES ============================== */
const ProtectedRoutes = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const token = window.localStorage.getItem('token');
  const { mfa } = useSelector((state) => state.auth);

  if (mfa && mfa.verified && mfa.verified) {
    return <Outlet />;
  } else if (isAuthenticated && token) {
    return <Navigate to="/mfa-setup" />;
  } else {
    return <Navigate to="/" />;
  }
};

export default ProtectedRoutes;
